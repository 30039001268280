import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "../pages/HomePage";
// import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUsPage from "../pages/ContactUsPage";
import MobileApp from "../pages/MobileAppPage";
import WebApp from "../pages/WebAppPage";
import Websites from "../pages/Website";
// import About from '../pages/AboutUsPage'
import Mission from "../pages/Mission";
import Who_we_are from "../pages/Who_we_are";
import Work_with_us from "../pages/Work_with_us";
import About from "../pages/About";
import NewNavbar from "../components/NewNavbar";
import Projects from "../pages/Projects";
import Career from "../pages/Career";
import Blogs from "../pages/Blogs";
import BlogDetail from "../components/BlogDetail";
import Location from "../components/Location"

const PublicRoutes = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin')

  return (
    <>
      {/* {!isAdminRoute && <Navbar />} */}
      {!isAdminRoute && <NewNavbar />}
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/contact/location" element={<Location />} />
          <Route path="/website" element={<Websites />} />
          <Route path="/webapp" element={<WebApp />} />
          <Route path='/projects' element ={<Projects/>}/>
          <Route path="/mobileapp" element={<MobileApp />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/about" element={<About />}>
            <Route index element={<Who_we_are />} />
            <Route path="mission" element={<Mission />} />
            <Route path="who-we-are" element={<Who_we_are />} />
            <Route path="company-profile" element={<Work_with_us />} />
          </Route>
        </Routes>
        {!isAdminRoute && <Footer />}
      </>
  );
};

export default PublicRoutes;
