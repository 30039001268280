import { team1, team2, team3, customer2, customer4, customer6 } from "./assets";
export const testimonials = [
  {
    id: 1,
    name: "Marie Jordan",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    image: customer4,
  },
  {
    id: 2,
    name: "Ann Brown",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    image: customer2,
  },
  {
    id: 3,
    name: "Andrew Bill",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    image: customer6,
  },
  {
    id: 4,
    name: "Jason Stawer",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    image: team1,
  },
  {
    id: 5,
    name: "Lisa Green",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    image: team2,
  },
  {
    id: 6,
    name: "Anna Doe",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    image: team3,
  },
];

//Dropdown items

// export const MenuItems = [
//   {
//     title: "Desktop App",
//     path: "/webapp",
//     cName: "dropdown-link",
//   },
//   {
//     title: "Website",
//     path: "/website",
//     cName: "dropdown-link",
//   },
//   {
//     title: "Mobile App",
//     path: "/mobileapp",
//     cName: "dropdown-link",
//   },
// ];

export const AboutItems = [
  {
    title: "Mission",
    path: "about/mission",
    cName: "dropdown-link",
  },
  {
    title: "Who we are?",
    path: "about/who-we-are",
    cName: "dropdown-link",
  },
  {
    title: "Work with us",
    path: "about/work-with-us",
    cName: "dropdown-link",
  },
];

export const MenuItems = [
  {
    title: "Home",
    path :"/",
    subMenu: [
      { title: "Welcome to GTS",
        path:"/"
       },
      { title: "Know us better",
        path:"/"
       },
    ],
  },
  {
    title: "About Us",
    subMenu: [
      { title: "Who we are?",
        path:"/about"
       },
      { title: "Company Profile",
        path:"/about/company-profile"
       },
      { title: "Mission & Vision",
        path:"/about/mission"
       },
      { title: "Geek Team",
        path:"/about"
       },
      {
        title: "Opportunities",
        subMenu: [
          { title: "Career",
            path:"/career"
           },
          { title: "Investor" },
          { title: "Trainer" },
        ],
      },
    ],
  },
  {
    title: "Services",
    subMenu: [
      {
        title: "Software Development",
        subMenu: [
          { title: "Web Development",
            path:"/website"
          },
          { title: "Mobile App Development",
            path:"/mobileapp"
           },
          { title: "Desktop App Development",
            path:"/webapp"
           },
        ],
      },
      {
        title: "Consulting",
        subMenu: [{ title: "IT" }, { title: "Business" }],
      },
      {
        title: "Training",
        subMenu: [
          { title: "Microsoft Word, Excel & Power-point" },
          { title: "Computer Aided Design (CAD)" },
          { title: "Programming (Python, NodeJS, Php)" },
        ],
      },
    ],
  },
  {
    title: "Projects",
    subMenu: [
      {
        title: "Websites",
        subMenu: [
          { title: "E-commerce" },
          { title: "Restaurant" },
          { title: "IT Company" },
        ],
      },
      {
        title: "Mobile Applications",
        subMenu: [
          { title: "Educational App" },
          { title: "Travel App" },
          { title: "Media App" },
        ],
      },
      {title:"All projects",
        path:"/projects"
      }
    ],
  },
  {title:"Blogs",
    path:"/blogs"
  },
  {
    title: "Contact Us",
    subMenu: [
      { title: "Contact Information",
        path:'/contact'
       },
      { title: "Inquiry Form",
        path:"/contact"
       },
      { title: "Location Map",
        path:'/contact/location'
       },
    ],
  },
  {
    title: "Payments",
    subMenu: [
      { title: "Paypal" },
      { title: "Khalti" },
      { title: "Esewa" },
    ],
  },
];