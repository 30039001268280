import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col } from "antd";
import { RxCross1 } from "react-icons/rx";
import { apiURL } from "../../assets/data/api";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { FaRegEdit } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi2";
import JoditEditor from "jodit-react"
import HTMLReactParser from "html-react-parser/lib/index";

export default function Blogs() {
  const [addBlog, setAddBlog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [flag, setFlag] = useState(false);
  const [editBlogId, setEditBlogId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [blogData, setBlogData] = useState({
    date: "",
    title: "",
    image: null,
    description: "",
    status: "",
    author: "",
  });

  const editor = useRef(null)


  useEffect(() => {
    setLoading(true);
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${apiURL}/blog`);
        setBlogs(response.data.data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [flag]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlogData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setBlogData((prevData) => ({ ...prevData, image: file }));
  };

  useEffect(() => {
    if (editBlogId) {
      const fetchBlog = async () => {
        try {
          const response = await axios.get(`${apiURL}/blog/${editBlogId}`);
          setBlogData({
            title: response.data.data.title,
            description: response.data.data.description,
            date: response.data.data.date,
            author: response.data.data.author,
            status: response.data.data.status,
            image: null, // Set to null if the image should be replaced
          });
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchBlog();
    } else {
      setBlogData({
        title: "",
        image: null,
        description: "",
        status: "",
        author: "",
        date: "",
      });
    }
  }, [editBlogId]);

  const validateForm = () => {
    if (!blogData.title || !blogData.description || !blogData.author) {
      setErrorMessage("Please fill in all required fields.");
      return false;
    } else if (!blogData.image && !editBlogId) {
      setErrorMessage("Please select an image");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handleAddBlog = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", blogData.title);
      formData.append("description", blogData.description);
      formData.append("status", blogData.status);
      formData.append("date", blogData.date);
      formData.append("author", blogData.author);
      if (blogData.image) formData.append("image", blogData.image);

      try {
        await axios.post(`${apiURL}/blog`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Blog added");
        resetForm();
        setFlag(!flag);
      } catch (error) {
        toast.error("Failed to add blog");
        console.log(error.message);
      }
    }
  };

  const handleDeleteBlog = async (id) => {
    try {
      await axios.delete(`${apiURL}/blog/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setFlag(!flag);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again");
      console.log(error.message);
    }
  };

  const handleEditBlog = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", blogData.title);
      formData.append("description", blogData.description);
      formData.append("status", blogData.status);
      formData.append("date", blogData.date);
      formData.append("author", blogData.author);
      if (blogData.image) formData.append("image", blogData.image);

      try {
        await axios.put(`${apiURL}/blog/${editBlogId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Updated successfully");
        resetForm();
        setFlag(!flag);
      } catch (error) {
        toast.error("Failed to update. Please try again");
        console.log(error.message);
      }
    }
  };

  const resetForm = () => {
    setBlogData({
      title: "",
      image: null,
      description: "",
      status: "",
      author: "",
      date: "",
    });
    setAddBlog(false);
    setEditBlogId(null);
  };

  return (
    <>
      <Toaster />
      {!addBlog && (
        <>
          <div className="heading flex items-center w-full justify-between p-[16px]">
            <h1 className="text-xl font-[600] relative">Blogs</h1>
            {loading && (
              <div className="flex w-full justify-center absolute">
                <p>Loading...</p>
              </div>
            )}
            <Button
              onClick={() => setAddBlog(true)}
              className="border-[#1890ff]"
            >
              Add Blog
            </Button>
          </div>
          <div className="flex gap-8 flex-wrap justify-between mb-8">
          {blogs.length === 0 && !loading? (
                <p className="p-4">No any blogs</p>
              ) : (
                ""
              )}
            {blogs.map((blog) => (
              <Card
                key={blog.id}
                className="w-[37rem] min-h-[25rem] criclebox tablespace"
              >
                <img
                  src={blog.image || "/placeholder.png"} // Handle missing image
                  className="h-[15rem] object-cover rounded-md"
                  alt={blog.title}
                />
                <h1 className="px-[25px] pt-4 text-xl font-[600] text-black">
                  {blog.title}
                </h1>
                <p className="px-[25px] pb-[16px] break-words">
                  {HTMLReactParser(blog.description)}
                </p>
                <div className="flex items-center justify-between px-[25px] pb-[16px]">
                  <p className="text-gray-400">{blog.author}</p>
                  <p className="text-gray-400">{blog.date}</p>
                </div>
                <div className="icons px-[25px] pb-[16px] flex items-center gap-4">
                  <FaRegEdit
                    size={18}
                    className="hover:text-blue-400 cursor-pointer icon-hover"
                    onClick={() => {
                      setEditBlogId(blog.id);
                      setAddBlog(true);
                    }}
                  />
                  <HiOutlineTrash
                    size={18}
                    className="hover:text-red-400 cursor-pointer icon-hover"
                    onClick={() => handleDeleteBlog(blog.id)}
                  />
                </div>
              </Card>
            ))}
          </div>
        </>
      )}

      <Col xs="24" xl={24}>
        {addBlog && (
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 relative"
            title="Add Blog"
          >
            <RxCross1
              className="absolute top-5 right-8 cursor-pointer"
              onClick={resetForm}
            />
            <form action="" className="py-4 px-[25px] flex flex-col gap-4">
              <div className="flex items-center gap-8 flex-wrap justify-between">
                <div>
                  <label htmlFor="title" className="font-semibold">Title:</label>
                  <input
                    type="text"
                    name="title"
                    className="border rounded-md h-9 w-72 mx-2 p-2 focus:outline-none"
                    value={blogData.title}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="image" className="font-semibold">Image:</label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    className="mx-2 file:py-2 file:rounded-md file:px-4 file:bg-blue-400 file:text-white file:outline-none file:border-none cursor-pointer file:hover:bg-opacity-75 file:bg-opacity-100 transition-all ease-in-out duration-300 file:transition-all file:ease-in-out file:duration-300"
                    onChange={handleImageChange}
                  />
                </div>

                <div className="flex items-center">
                  <label htmlFor="author" className="font-semibold">Author:</label>
                  <input
                    type="text"
                    name="author"
                    className="border rounded-md h-9 w-72 mx-2 p-2 focus:outline-none"
                    value={blogData.author}
                    onChange={handleChange}
                  />
                </div>



                <div>
                  <label htmlFor="date" className="font-semibold">Date:</label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    onChange={handleChange}
                    className="bg-blue-400 rounded-md p-1 text-white mx-2 hover:bg-opacity-75 transition-all ease-in-out duration-300"
                    value={blogData.date}
                  />
                </div>



                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="description" className="font-semibold">Description:</label>
                  {/* <textarea
                    name="description"
                    rows="2"
                    cols="40"
                    className="border mx-2 rounded-md p-2 focus:outline-none resize-y"
                    value={blogData.description}
                    onChange={handleChange}
                  /> */}
                   <JoditEditor
                   ref={editor}
                    className="w-full"                    
                    value={blogData.description}
                    onChange={(newContent) =>
                      setBlogData((prevData) => ({
                        ...prevData,
                        description: newContent,
                      }))
                    }
                  />
                </div>

                <div>
                  <label htmlFor="status" className="font-semibold">Status:</label>
                  <select
                    name="status"
                    id="status"
                    className="bg-blue-400 rounded-md p-1 px-2 text-white mx-2 hover:bg-opacity-75 transition-all ease-in-out duration-300"
                    onChange={handleChange}
                    value={blogData.status}
                  >
                    <option value="published">Published</option>
                    <option value="draft">Draft</option>
                    <option value="archived">Archived</option>
                  </select>
                </div>
              </div>

              {errorMessage && (
                <p className="text-sm !text-red-500 !font-normal">
                  {errorMessage}
                </p>
              )}

              <Button
                type="button"
                className="border-[#1890ff] !w-28 self-center mt-4"
                onClick={editBlogId ? handleEditBlog : handleAddBlog}
              >
                Done
              </Button>
            </form>
          </Card>
        )}
      </Col>
    </>
  );
}
