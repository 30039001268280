import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiURL } from "../assets/data/api";
import { Card } from "antd"
import { NavLink } from "react-router-dom";
import HTMLReactParser from "html-react-parser/lib/index";

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/blog`
        );
        setBlogs(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    fetchBlogs();
  }, []);

const truncateText = (text, numWords) => {
    const words = text.split(' ');
    if (words.length <= numWords) return text;
  
    return words.slice(0, numWords).join(' ') + '...';
  };

  return (
    <div className="!min-h-[100vh]">
      <div className="h-[17vh] w-full relative">
        <h2 className="z-20 absolute bottom-0 px-8 text-gray-200">
          <NavLink to="/" className="text-gray-200 hover:text-gray-200">
            GTS /
          </NavLink>
          <span className="ml-2">Blogs</span>
        </h2>
      </div>
      <div className="projects-content flex flex-col justify-center items-center gap-4 -mt-8 py-8 mx-8">

        <h2 className="text-[44px]">All Blogs</h2>

        {blogs.length === 0 && loading === false ? (
              <p className="p-4">No any blogs.</p>
            ) : (
              ""
            )}
            {loading?(<p className="p-4">Loading...</p>):""}
        <div className="flex gap-8 flex-wrap justify-center">
          {blogs.map((blog) => (
              <NavLink to={`/blogs/${blog.id}`} key={blog.id}>
                  <Card
                  key={blog.id}
                  className="w-[28rem] min-h-[20rem] criclebox tablespace card-hover"
                              >
                  <img
                    src={blog.image || "/placeholder.png"} // Handle missing image
                    className="h-[15rem] object-cover rounded-md"
                    alt={blog.title}
                  />
                  <h1 className="px-[25px] pt-4 text-2xl font-[600] text-black">
                    {blog.title}
                  </h1>
                  <p className="px-[25px] pb-[16px] break-words text-md">
                  {truncateText(HTMLReactParser(blog.description), 30)}
                  </p>
                  <div className="flex items-center justify-between px-[25px] pb-[16px]">
                    <p className="text-gray-400  text-[16px]">{blog.author}</p>
                    <p className="text-gray-400  text-[16px]">{blog.date}</p>
                  </div>
                              </Card>
              </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}

