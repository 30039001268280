import React, { useEffect, useState } from "react";
import "../scss/globals.scss"
import{testimonials} from "../assets/data/Data";
import axios from "axios";
import { apiURL } from "../assets/data/api";

const Team = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/team`
        );
        setTeams(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    fetchTeams();
  }, []);

  return (
    <section className="testimonials section-p" id="testimonials">
      <div className="container">
        <div className="testimonials-content">
          <div className="section-t text-center">
            <h3>Our Team</h3>
            <p className="text">
            We are a team of Visionary leaders, IT experts, Electrical Engineers, and Computer Engineers, and more importantly Learner's enthusiasts.
            </p>
          </div>

          <div className="item-list text-white text-center px-[2rem] flex flex-wrap justify-center items-center">
          {teams.length === 0 && loading === false ? (
              <p className="p-4">No any teams</p>
            ) : (
              ""
            )}
            {loading?(<p className="p-4">Loading...</p>):""}
            {teams.map((team) => {
              return (
                <div
                  className="item translate-effect bg-dark flex flex-col items-center justify-center gap-4"
                  key={team.id}
                >
                  <div className="">
                    <img src={team.image} alt="" className="rounded-full h-44 w-44 object-cover" />
                  </div>
                  <div>
                  <h4 className="item-name">{team.name}</h4>
                  <p className="item-text !text-base !font-normal">{team.position}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
