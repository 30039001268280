import { Row, Col, Card, Button } from "antd";

import { useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { FaRegEdit } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi2";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { apiURL } from "../../assets/data/api";
import HTMLReactParser from "html-react-parser/lib/index";
import JoditEditor from "jodit-react"


export default function Projects() {
  const [data, setData] = useState([]);
  const [addProject, setAddProject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editProjectId, setEditProjectId] = useState(null);
  const [projectData, setProjectData] = useState({
    title: "",
    content: "",
    duration: "",
    image: null,
  });

  const editor = useRef(null)

  useEffect(() => {
    setLoading(true);
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${apiURL}/project`);
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    fetchTestimonials();
  }, [flag]);

  useEffect(() => {
    if (editProjectId) {
      const fetchTestimonial = async () => {
        try {
          const response = await axios.get(
            `${apiURL}/project/${editProjectId}`
          );
          setProjectData({
            title: response.data.data.title,
            content: response.data.data.content,
            duration: response.data.data.duration,
            image: response.data.data.image,
          });
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchTestimonial();
    } else {
      setProjectData({ title: "", content: "", duration: "", image: null });
    }
  }, [editProjectId]);

  const validateForm = () => {
    if (!projectData.title || !projectData.duration || !projectData.content) {
      setErrorMessage("Please fill in all required fields.");
      return false;
    } else if (!projectData.image) {
      setErrorMessage("Please select an image");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  // Set user-input data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Set uploaded file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProjectData((prevData) => ({ ...prevData, image: file }));
  };

  const handleAddProject = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", projectData.title);
      formData.append("content", projectData.content);
      formData.append("duration", projectData.duration);
      formData.append("image", projectData.image);
      try {
        await axios.post(`${apiURL}/project`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Project added");
        setProjectData({ title: "", content: "", duration: "", image: null });

        setFlag(!flag); // Update flag to trigger re-fetch
      } catch (error) {
        toast.error("Failed to add service");
        console.log(error.message);
      }
      setAddProject(false);
    }
  };

  const handleDeleteProject = async (id) => {
    try {
      await axios.delete(`${apiURL}/project/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setFlag(!flag);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again");
      console.log(error.message);
    }
  };

  const handleUpdateProject = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", projectData.title);
      formData.append("content", projectData.content);
      formData.append("duration", projectData.duration);
      formData.append("image", projectData.image);
      try {
        await axios.put(`${apiURL}/project/${editProjectId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setAddProject(false);
        setEditProjectId(null);
        setProjectData({
          name: "",
          content: "",
          designation: "",
          image: null,
        });
        setFlag(!flag); // Update flag to trigger re-fetch
        toast.success("Updated successfully");
      } catch (error) {
        toast.error("Failed to update. Please try again");
        console.log(error.message);
      }
    }
  };

  return (
    <>
      <div className="tabled relative">
        <Toaster />
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            {!addProject && !editProjectId && (
              <>
                <div className="heading flex items-center w-full justify-between p-[16px]">
                  <h1 className=" text-xl font-[600]">Projects</h1>
                  {loading && (
                    <div className="flex w-full justify-center absolute">
                      <p>Loading...</p>
                    </div>
                  )}
                  <Button
                    onClick={() => setAddProject(true)}
                    className="border-[#1890ff]"
                  >
                    Add Project
                  </Button>
                </div>
                <Card className="mb-24 tablespace">
                  <div className="overflow-x-auto">
                    <table className="min-w-full leading-normal">
                      {data.length === 0 && !loading? (
                        <p className="p-4">No any projects</p>
                      ) : (
                        ""
                      )}
                      <thead>
                        <tr>
                          <th className="px-20 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Title
                          </th>
                          <th className="px-8 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[40%]">
                            Description
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Duration
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td className="px-6 py-5 border-b border-gray-200 bg-white text-sm">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10 overflow-hidden">
                                  <img
                                    className="w-full h-full rounded-full border"
                                    src={item.image}
                                    alt={item.title}
                                  />
                                </div>
                                <div className="ml-3">
                                  <p className="font-normal whitespace-no-wrap">
                                    {item.title}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-8 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="font-normal break-words">
                                {HTMLReactParser(item.content)}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="font-normal whitespace-no-wrap">
                                {item.duration}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <div className="flex items-center gap-4">
                                <FaRegEdit
                                  size={18}
                                  className="text-gray-600 hover:text-blue-400 cursor-pointer mr-2 icon-hover"
                                  onClick={() => {
                                    setEditProjectId(item.id);
                                    setAddProject(true);
                                  }}
                                />
                                <HiOutlineTrash
                                  size={18}
                                  className="text-gray-600 hover:text-red-400 cursor-pointer icon-hover"
                                  onClick={() => handleDeleteProject(item.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </>
            )}

            {addProject && (
              <Card
                bordered={false}
                className="criclebox tablespace mb-24 relative"
                title="Add Project"
              >
                <RxCross1
                  className="absolute top-5 right-8 cursor-pointer"
                  onClick={() => {
                    setAddProject(false);
                    setEditProjectId(null);
                  }}
                />
                <form action="" className="py-4 px-[25px] flex flex-col gap-4">
                  <div className="flex items-center gap-8 flex-wrap justify-between">
                    <div>
                      <label htmlFor="title" className="font-semibold">
                        Title:
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="border rounded-md h-10 w-72 mx-2 p-2 focus:outline-none"
                        onChange={handleChange}
                        value={projectData.title}
                      />
                    </div>

                    <div className="relative">
                      <label htmlFor="duration" className="font-semibold">
                        Duration:
                      </label>
                      <input
                        name="duration"
                        type="text"
                        className="border rounded-md h-10 w-72 mx-2 p-2 focus:outline-none"
                        onChange={handleChange}
                        value={projectData.duration}
                      />
                    </div>

                    <div>
                      <label htmlFor="image" className="font-semibold">
                        Image:
                      </label>
                      <input
                        type="file"
                        id="image"
                        name="image"
                        className="mx-2 file:py-2 file:rounded-md file:px-4 file:bg-blue-400 file:text-white file:outline-none file:border-none cursor-pointer file:hover:bg-opacity-75 file:bg-opacity-100 transition-all ease-in-out duration-300 file:transition-all file:ease-in-out file:duration-300"
                        onChange={handleImageChange}
                      />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                      <label htmlFor="description" className="font-semibold">
                        Description:
                      </label>
                      <JoditEditor
                      ref={editor} 
                      className="w-full"
                      value={projectData.content}
                        onChange={(newContent) =>
                          setProjectData((prevData) => ({
                            ...prevData,
                            content: newContent,
                          }))
                        }/>
                    </div>
                  </div>

                  {errorMessage && (
                    <p className="text-sm !text-red-500 !font-normal">
                      {errorMessage}
                    </p>
                  )}

                  <Button
                    type="button"
                    className="border-[#1890ff] !w-28 self-center mt-4"
                    onClick={
                      editProjectId ? handleUpdateProject : handleAddProject
                    }
                  >
                    Done
                  </Button>
                </form>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
