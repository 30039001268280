import React from "react";
import "../scss/globals.scss"
import { FaFacebook, FaLinkedin} from "../assets/data/assets"
// import {useTheme} from "../context/ThemeContext"
import {Link} from "react-router-dom"
import {logo, footerimg} from "../assets/data/assets";
import {  FaInstagram } from "react-icons/fa";


const Footer = () => {
  // const {isDarkMode} = useTheme()

  const clickToTop=()=>{
    window.scrollTo(0,0);
  }
  return (
    <>
    {/* <img src={footerimg} alt="" /> */}
     {/* <footer className={`footer ${isDarkMode? "footer-dark-mode":"footer-light-mode"}` }> */}
    
    <footer className="footer">
      <div className="container w-100">
        <div className="wrapper">
          <div className="footer-widget">
            <Link to="/">
              <img src={logo} alt=""  className="logo"/>
            </Link>
            <p className="desc">
              Empowering Innovation, Connecting Tomorrow <br /> Your IT Solutions Partner.
            </p>
            <ul className="socials fa">
              <li>
                <Link to="https://www.facebook.com/geeknepal/">
                  <i>
                    <FaFacebook />
                  </i>
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/geek_tech_solutions_pvt_ltd/">
                  <i>
                    <FaInstagram />
                  </i>
                </Link>
              </li>
              <li>
                <Link to="https://np.linkedin.com/company/geeknepalofficial">
                  <i>
                    <FaLinkedin />
                  </i>
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-widget">
            <h6>Quick Link</h6>
            <ul className="links">
              <li>
                <Link to="/" onClick={clickToTop}>home</Link>
              </li>
              <li>
                <Link to="/about" onClick={clickToTop}>about</Link>
              </li>
              <li>
                <Link to="#" onClick={clickToTop}>testimonial</Link>
              </li>
              <li>
                <Link to="/contact" onClick={clickToTop}>contact</Link>
              </li>
            </ul>
          </div>
          <div className="footer-widget">
            <h6>Services</h6>
            <ul className="links">
              <li>
                <Link to="/website" onClick={clickToTop}>web design</Link>
              </li>
              <li>
                <Link to="/webapp" onClick={clickToTop}>Desktop App</Link>
              </li>
              <li>
                <Link to="/mobileapp" onClick={clickToTop}>Mobile App</Link>
              </li>
             
              <li>
                <Link to="" onClick={clickToTop}>blog writing</Link>
              </li>
            </ul>
          </div>
          <div className="footer-widget">
            <h6>Help &amp; Support</h6>
            <ul className="links">
              <li>
                <Link to="#" onClick={clickToTop}>support center</Link>
              </li>
              <li>
                <Link to="#" onClick={clickToTop}>live chat</Link>
              </li>
              <li>
                <Link to="#" onClick={clickToTop}>FAQ</Link>
              </li>
              <li>
                <Link to="#" onClick={clickToTop}>terms &amp; conditions</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright-wrapper">
          <p style={{color:"white"}}>
          Copyright 2024 Geek Tech Solutions Pvt. Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;
