import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/utils.css"
import PublicRoutes from "./routes/PublicRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";

function App() {

  return (
    <>
        <DashboardRoutes/>
        <PublicRoutes/>
    </>
  );
}

export default App;