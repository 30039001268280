import { Col, Card, Button } from "antd";
import { RxCross1 } from "react-icons/rx";
import { useEffect, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi2";
import { FaRegEdit } from "react-icons/fa";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { apiURL } from "../../assets/data/api";

function Teams() {
  const [teams, setTeams] = useState([]);
  const [addTeam, setAddTeam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [editOptions, setEditOptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editTeamId, setEditTeamId] = useState(null);
  const [teamData, setTeamData] = useState({
    name: "",
    position: "",
    image: null,
  });

  useEffect(() => {
    setLoading(true);
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/team`
        );
        setTeams(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    fetchTeams();
  }, [flag]);

  useEffect(() => {
    if (editTeamId) {
      const fetchTeam = async () => {
        try {
          const response = await axios.get(
            `${apiURL}/team/${editTeamId}`
          );
          setTeamData({
            name: response.data.data.name,
            position: response.data.data.position,
            image: response.data.data.image,
          });
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchTeam();
    } else {
      setTeamData({ name: "", position: "", image: null });
    }
  }, [editTeamId]);

  const validateForm = () => {
    if (!teamData.name || !teamData.position) {
      setErrorMessage("Please fill in all required fields.");
      return false;
    } else if (!teamData.image) {
      setErrorMessage("Please select an image");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  // Set user-input data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Set uploaded file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setTeamData((prevData) => ({ ...prevData, image: file }));
  };

  const handleAddTeam = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("name", teamData.name);
      formData.append("position", teamData.position);
      formData.append("image", teamData.image);
      try {
        await axios.post(
          `${apiURL}/team`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Member added");
        setTeamData({ name: "", position: "", image: null });

        setFlag(!flag); // Update flag to trigger re-fetch
      } catch (error) {
        toast.error("Failed to add service");
        console.log(error.message);
      }
      setAddTeam(false);
    }
  };

  const handleDeleteTeam = async (id) => {
    try {
      await axios.delete(
        `${apiURL}/team/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setFlag(!flag);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again");
      console.log(error.message);
    }
  };

  const handleUpdateTeam = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("name", teamData.name);
      formData.append("position", teamData.position);
      formData.append("image", teamData.image);
      try {
        await axios.put(
          `${apiURL}/team/${editTeamId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setAddTeam(false);
        setEditTeamId(null);
        setTeamData({
          name: "",
          position: "",
          image: null,
        });
        setFlag(!flag); // Update flag to trigger re-fetch
        toast.success("Updated successfully");
      } catch (error) {
        toast.error("Failed to update. Please try again");
        console.log(error.message);
      }
    }
  };

  return (
    <>
      <div className="tabled">
        <Toaster />
        {!addTeam && !editTeamId && (
          <>
            <Col xs="24" xl={24}>
              <div className="heading flex items-center w-full justify-between p-[16px]">
                <h1 className="text-xl font-[600]">Our Team</h1>
                {loading && (
                  <div className="flex w-full justify-center absolute">
                    <p>Loading...</p>
                  </div>
                )}
                <div>
                  <Button
                    onClick={() => setAddTeam(true)}
                    className="border-[#1890ff] mx-4"
                  >
                    Add Member
                  </Button>
                  <Button
                    onClick={() => setEditOptions(!editOptions)}
                    className="border-[#1890ff]"
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </Col>

            <div className="flex gap-6 justify-start w-full flex-wrap mb-8">
              {teams.map((team) => (
                <Card
                  key={team.id}
                  className={`h-fit w-[225px] !px-4 !py-0 flex flex-col items-center justify-center ${
                    !editOptions ? "hover:scale-105" : ""
                  } transition-all ease-in-out duration-400 cursor-pointer relative`}
                >
                  {editOptions && (
                    <div className="absolute flex justify-between w-full left-0 top-0 px-2 py-2">
                      <FaRegEdit
                        size={18}
                        className="hover:text-blue-400 icon-hover"
                        onClick={() => {
                          setEditTeamId(team.id);
                          setAddTeam(true);
                        }}
                      />
                      <HiOutlineTrash
                        size={18}
                        className="hover:text-red-400 cursor-pointer icon-hover"
                        onClick={() => handleDeleteTeam(team.id)}
                      />
                    </div>
                  )}
                  <div className="image">
                    <img
                      src={team.image}
                      alt=""
                      srcSet=""
                      className="h-44 object-cover rounded-full"
                    />
                  </div>
                  <div className="title flex flex-col justify-center w-full items-center mt-6">
                    <h2 className="text-xl text-black w-44">{team.name}</h2>
                    <p>{team.position}</p>
                  </div>
                </Card>
              ))}
            </div>
          </>
        )}

        <Col xs="24" xl={24}>
          {addTeam && (
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 relative"
              title="Add Member"
            >
              <RxCross1
                className="absolute top-5 right-8 cursor-pointer"
                onClick={() => {
                  setAddTeam(false);
                  setEditTeamId(null);
                }}
              />
              <form action="" className="py-4 px-[25px] flex flex-col gap-4">
                <div className="flex items-center gap-8 flex-wrap justify-between">
                  <div>
                    <label htmlFor="name">Full Name:</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="border rounded-md h-10 w-56 mx-2 p-2 focus:outline-none"
                      value={teamData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="position">Position:</label>
                    <input
                      type="text"
                      id="position"
                      name="position"
                      className="border rounded-md h-10 w-44 mx-2 p-2 focus:outline-none"
                      value={teamData.position}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="image">Image:</label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      className="mx-2 file:py-2 file:rounded-md file:px-4 file:bg-blue-400 file:text-white file:outline-none file:border-none cursor-pointer file:hover:bg-opacity-75 file:bg-opacity-100 transition-all ease-in-out duration-300 file:transition-all file:ease-in-out file:duration-300"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>

                {errorMessage && (
                  <p className="text-red-500 !font-normal">{errorMessage}</p>
                )}

                <Button
                  type="button"
                  className="border-[#1890ff] !w-28 self-center mt-4"
                  onClick={editTeamId ? handleUpdateTeam : handleAddTeam}
                >
                  Done
                </Button>
              </form>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}

export default Teams;
