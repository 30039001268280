import React, { useEffect, useState } from "react";
import Image from "../../src/assets/images/illustration_login.png";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function AdminLogin() {
  const [data, setData] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);

  const validateForm = () => {
    if (!data.email) {
      setErrorMessage("Email cannot be empty");
      return false;
    } else if (!data.password) {
      setErrorMessage("Password cannot be empty");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://gts-website-api.onrender.com/api/v1/admin/login",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          setLoading(false);
          localStorage.setItem("token", response.data.data.token);
          navigate("/admin/dashboard");
          toast.success("Login sucessful");
          setData({ email: "", password: "" });
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        toast.error("Login failed");
      }
    }
  };

  return (
    <div className="h-screen w-full flex flex-col gap-8 justify-center items-center">
      <div className="title">
        <h2 className="text-xl">Admin Login</h2>
      </div>
      <Toaster />
      {loading ? (
        <p>Please wait a moment...</p>
      ) : (
        <div className="login-form">
          <div className="h-fit md:min-h-[30rem] md:w-[45rem] w-fit bg-[#ffff] rounded-md flex justify-between items-center gap-4 py-6 md:px-8">
            <div className="left flex flex-col justify-center items-center gap-4">
              <p className="border-b border-slate-400 w-[50%] text-center py-2">
                Please login
              </p>
              <form
                action=""
                className="flex flex-col px-10 w-80 relative"
                onSubmit={handleLogin}
              >
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-gray-200 rounded-md mb-4 h-8 p-2 focus:border-[#85D3FB]"
                  value={data.email}
                  onChange={handleChange}
                />
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-200 rounded-md h-8 p-2 mb-6 focus:border-[#85D3FB]"
                  value={data.password}
                  onChange={handleChange}
                />


                {errorMessage && (
                  <p className="text-sm !text-red-500 !font-normal absolute bottom-[20%] left-10">
                    {errorMessage}
                  </p>
                )}

                <button
                  type="submit"
                  className="text-base bg-[#85D3FB] w-fit self-center px-6 py-2 rounded-md hover:text-white transition-all ease-in-out duration-100 mt-8"
                >
                  Login 
                </button>
              </form>

            </div>

            <div className="right hidden md:block">
              <img src={Image} alt="Login" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
