import React from "react";
import {
  testimonial,
  MobileAppService,
  MobileImage1,
  MobileImage2,
  MobileImage3,
} from "../assets/data/assets";
import "../scss/globals.scss";
// import { useTheme } from '../context/ThemeContext';

const MobileAppPage = () => {
  // const {isDarkMode, toogleDarkMode}= useTheme();
  return (
    <div className="mobileapp-service-div ">
      {/* {isDarkMode ?<MdLightMode className="vid-icon" />  :  <MdDarkMode className="vid-icon" />} */}
      <div className="contact-bg-img">
        <h2>Mobile App</h2>
      </div>
      <div className="top-detail-div">
        <div className="service-div">
          <div className="left-div">
            <h2  >Our Service</h2>
            <img src={MobileAppService} alt="" className="our-service-img" />
          </div>
          <div className="right-div">
            <div className="top-div">
              <h3 style={{ marginTop: '60px' }}>Geek Tech Solution</h3>
              <p>
                Welcome to Geek Tech Solution, where innovation meets excellence
                in the world of information technology. Our comprehensive range
                of services is designed to empower businesses, streamline
                operations, and foster growth in the rapidly evolving digital
                landscape.
              </p>
              <br />
            </div>
            <div className="middle-div">
              <h3>Why Choose Geek Tech Solution?</h3>

              <p>
                Elevate your business with Geek Tech Solution a trusted partner
                committed to excellence. We blend cutting-edge technology,
                unparalleled expertise, and a client-centric approach to deliver
                tailored solutions. Choose us for innovation, reliability, and a
                collaborative journey towards your success.
              </p>
              <br />
            </div>
            <div className="bottom-div">
              <h3>Our Expertise</h3>
              <p>
                With a dedicated team of seasoned professionals, Geek Tech
                Solution brings a wealth of expertise in custom software
                development, cloud solutions, cybersecurity, and more. We
                empower businesses with strategic insights and top-notch
                solutions, ensuring they thrive in the dynamic landscape of
                technology
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mid-div">
        <div className="mid-left-div">
          <h2>Why Choose Geek Tech Solution</h2>
          <h2>For Mobile App Development ?</h2>
          <br />
          <p>
            Cutting-Edge Innovation, Seamless Solutions At Geek Tech Solution,
            we stand at the forefront of mobile app development, blending
            innovation with expertise to craft unparalleled solutions that
            elevate your business in the digital realm. Choosing us means
            choosing a partner dedicated to turning your app vision into a
            reality while ensuring a seamless and user-centric experience.
          </p>
          <br />
          <h3>Expert Team of Developers:</h3>
          <p>
            Our team consists of seasoned developers with a proven track record
            of creating high-performing, visually stunning mobile applications.
            From concept to deployment, our experts leverage the latest
            technologies and industry best practices to deliver apps that exceed
            expectations.
          </p>
          <br /> <h3>Customized Solutions for Every Need:</h3>
          <p>
            We understand that each business is unique. That's why we offer
            tailor-made mobile app development solutions that align with your
            specific requirements and objectives. Whether you need an intuitive
            consumer app or a sophisticated enterprise solution, we have the
            expertise to deliver precisely what you envision.
          </p>
          <br />
          <h3>User-Centric Design Philosophy:</h3>
          <p>
            User experience is at the core of our development process. We
            prioritize usability and engagement, ensuring that your app not only
            looks great but also provides a delightful experience for your
            target audience. Our designs are intuitive, responsive, and geared
            towards driving user satisfaction.
          </p>
          <br />
          <h3>Robust and Scalable Architecture:</h3>
          <p>
            Geek Tech Solution is committed to building apps that grow with your
            business. We implement robust and scalable architectures, allowing
            your app to handle increasing user loads and feature expansions
            without compromising on performance or security.
          </p>
          <br />
          <h3>Transparent Communication and Collaboration:</h3>
          <p>
            We believe in transparent and collaborative partnerships. Throughout
            the development process, we maintain open lines of communication,
            keeping you informed and involved at every stage. Your feedback is
            not only valued but actively sought to ensure that the end product
            exceeds your expectations.
          </p>
          <br />
          <h3>Rigorous Testing and Quality Assurance:</h3>
          <p>
            Quality is non-negotiable at Geek Tech Solution. Before your app
            reaches the hands of users, it undergoes rigorous testing and
            quality assurance processes. This commitment to excellence ensures a
            bug-free, reliable, and secure mobile application that reflects
            positively on your brand.
          </p>
          <br />
          <h3>Timely Delivery and Cost-Effective Solutions:</h3>
          <p>
            Time is of the essence, and we respect that. Our agile development
            methodology allows us to deliver projects on time without
            compromising quality. Additionally, we provide cost-effective
            solutions, ensuring that you receive maximum value for your
            investment.
          </p>
          <br />
          <h3>Scalability</h3>
          <p>
            Designs mobile apps with scalability in mind, enabling them to grow and
            adapt to increased user demands and evolving business needs.
          </p><br />
          <h3> Ongoing Support and Maintenance:</h3>
          <p>
            Our commitment doesn’t end with the delivery of the app. Geek Tech
            Solution provides ongoing support and maintenance services, ensuring
            that your application remains up-to-date, secure, and aligned with
            the latest technological advancements. Choose Geek Tech Solution for
            mobile app development, and embark on a journey of innovation,
            reliability, and success. Let us turn your app aspirations into a
            tangible, high-performing reality that stands out in the competitive
            app landscape.
          </p>
          <br />{" "}
        </div>
        <div className="mid-right-div">
          <div className="image4">
            <img src={MobileImage1} alt="" />
          </div>
          <div className="image5">
            <img src={MobileImage3} alt="" />
          </div>
          <div className="image6">
            <img src={MobileImage2} alt="" />
          </div>
        </div>
      </div>
      <div className="last-div">
        <div className="last-left-div">
          <h3>Face of Satisfaction</h3>
          <div className="image4">
            <img src={testimonial} alt="Client testimonial image" />
          </div>
        </div>

        <div className="last-right-div">
          <h3>A Client Testimonial for Geek Tech Solution</h3>
          <br />

          <p>
            I am delighted to share my exceptional experience with Geek Tech
            Solutions. From the inception of my project to its completion, their
            team demonstrated unwavering professionalism, expertise, and a
            genuine commitment to delivering outstanding results. The level of
            detail and creativity invested in my website development far
            exceeded my expectations.
          </p>
          <br />
          <p>
            Geek Tech Solutions not only met but exceeded every milestone,
            providing a seamless and collaborative experience. Their ability to
            understand and translate my vision into a functional and visually
            stunning website was truly commendable. The team's responsiveness,
            transparent communication, and post-launch support further
            solidified my confidence in their capabilities. I wholeheartedly
            recommend Geek Tech Solutions to anyone seeking top-tier web
            development services – a team that goes above and beyond to turn
            ideas into digital masterpieces.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileAppPage;
