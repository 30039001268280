import React, { useEffect, useState } from "react";
import ServicesCard from "../utils/ServicesCard";
import "../scss/globals.scss";
import axios from "axios";
import { apiURL } from "../assets/data/api";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/service`
        );
        setServices(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    fetchServices();
  }, []);

  return (
    <>
      <div className=" flexs flexs-col justify-center items-center lg:px-32 px-5 border min-h-[100vh] -mt-8 section-p">
        <div className="container flex flex-col justify-center !gap-y-0">
          <div className="flexs flexs-center">
            <h2 className="text-center section-t">Our Services</h2>
          </div>

          <div className=" flex flex-wrap justify-center gap-5 px-[2rem]">
            {services.length === 0 && loading === false ? (
              <p className="p-4">No any services</p>
            ) : (
              ""
            )}
            {loading?(<p className="p-4">Loading...</p>):""}
            {!loading && services.map((service) => (
              <ServicesCard
                key={service.id}
                image={service.image}
                title={service.title}
                content={service.content}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
