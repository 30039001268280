import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { apiURL } from "../assets/data/api";

export default function BlogDetail() {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${apiURL}/blog/${id}`);
        setBlog(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    fetchBlog();
  }, [id]);

  if (loading) return <p>Loading...</p>;

  if (!blog) return <p>Blog not found.</p>;

  return (
    <div className="min-h-[100vh]">
      <div className="h-[17vh] w-full relative">
        <h2 className="z-20 absolute bottom-0 px-8 text-gray-200">
          <NavLink to="/" className="text-gray-200 hover:text-gray-200">
            GTS /
          </NavLink>
          <span className="ml-2">Blogs</span>
        </h2>
      </div>

      <div className="blog-detail flex justify-center items-center w-full px-[24px] py-[16px] -mt-8">
        <div className="flex flex-col justify-center w-[70%]">
          <h1 className="text-3xl font-bold">{blog.title}</h1>
          <img
            src={blog.image} 
            alt={blog.title}
            className="w-full h-[25rem] object-cover mt-4"
          />
          <p className="mt-4 text-md">{blog.description}</p>
          <p className="mt-2 text-gray-600 text-[16px]">Author: {blog.author}</p>
          <p className="text-gray-600 text-[16px]">Date: {blog.date}</p>
        </div>
      </div>
    </div>
  );
}
