// import {video} from "../assets/data/assets"
import {video2} from "../assets/data/assets"
// import { useTheme } from "../context/ThemeContext";
import "../scss/globals.scss";
// import {MdDarkMode, MdLightMode} from "react-icons/md"

const VideoDiv = () => {
//  const {isDarkMode, toogleDarkMode}= useTheme();


    return (
    // <div className={`vid-div ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
    <div className="vid-div" >
      <div className="vid-icon-div"   >
      {/* {isDarkMode ?<MdLightMode className="vid-icon" />  :  <MdDarkMode className="vid-icon" />} */}
      </div>
      <video autoPlay muted loop>
        <source src={video2} className="video" />
      </video>
    </div>
  );
};

export default VideoDiv;
