import React from "react";
import VideoDiv from "../components/Video-div";
import Services from "../components/Service";
import Expertise from "../components/Expertise";
import Team from "../components/Team"
import About from "../components/About";

const HomePage = () => {
  return (
    <>
      <VideoDiv />
      <About/>
      <Services />
      <Expertise />
      <Team/>
    </>
  );
};

export default HomePage;
