import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiURL } from "../assets/data/api";
import { NavLink } from "react-router-dom";
import HTMLReactParser from "html-react-parser/lib/index";

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/project`
        );
        setProjects(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    fetchProjects();
  }, []);
  return (
    <div className="min-h-[90vh]">
      <div className="h-[17vh] w-full relative">
        <h2 className="z-20 absolute bottom-0 px-8 text-gray-200">
          <NavLink to="/" className="text-gray-200 hover:text-gray-200">
            GTS /
          </NavLink>
          <span className="ml-2">Projects</span>
        </h2>
      </div>
      <div className="projects-content flex flex-col justify-center items-center gap-4 -mt-8 py-8 mx-8">

        <h2 className="text-[44px]">Ongoing Projects</h2>

        <div className="flex gap-8 flex-wrap justify-center">
        {projects.length === 0 && loading === false ? (
              <p className="p-4">No any services</p>
            ) : (
              ""
            )}
            {loading?(<p className="p-4">Loading...</p>):""}
          {projects.map((project) => (
            <div className="!bg-white min-h-60 w-[22rem] rounded-md shadow-md flex flex-col items-center justify-center gap-4 p-8 card-hover" key={project.id}>
              <h2 className="text-black font-[600] text-2xl">{project.title}</h2>
              <p className="text-center text-md">{HTMLReactParser(project.content)}</p>
              <p className="!text-[16px]">{project.duration}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// 227 359
