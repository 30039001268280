import React from 'react'

export default function Location() {
    const gtsLocation =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.4776506841445!2d85.33465117555973!3d27.671628376203014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb193bccff0ec1%3A0x4b773948d297177a!2sVIP%20Builders%20And%20Developers%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1702269579124!5m2!1sen!2snp";
  return (
    <div className="map-section">
    <h2>
      We are happy to meet in person if you are close to our office
      location.
    </h2>
    <div
      className="officeLocation"
      style={{ width: "100%", height: "60vh" }}
    >
      <iframe
        src={gtsLocation}
        width="100%"
        height="100%"
        style={{ border: "0" }}
        allowFullScreen="false"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  </div>
  )
}
