import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { apiURL } from "../../assets/data/api";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { toast, Toaster } from "react-hot-toast";

export default function Inbox() {
  const [messages, setMessages] = useState([]);
  const [singleMessage, setSingleMessage] = useState({});
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hoveredMessageId, setHoveredMessageId] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredMessageId(id);
  };

  const handleMouseLeave = () => {
    setHoveredMessageId(null);
  };

  useEffect(() => {
    setLoading(true);
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${apiURL}/contact`);
        setMessages(response.data.data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [flag]);

  const handleDeleteMessage = async (id) => {
    try {
      await axios.delete(`${apiURL}/contact/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setFlag(!flag);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again");
      console.log(error.message);
    }
  };

  const handleSingleMessage = async (id) => {
    try {
      const response = await axios.get(`${apiURL}/contact/${id}`);
      setSingleMessage(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const truncateText = (text, numWords) => {
    const words = text.split(" ");
    if (words.length <= numWords) return text;

    return words.slice(0, numWords).join(" ") + "...";
  };

  return (
    <>
      <Toaster />
      <div className="heading flex items-center w-full justify-between p-[16px]">
        <h1 className=" text-xl font-[600]">Inbox</h1>
      </div>
      <Card className="md:h-[70vh] h-full mb-8">
        <div className="w-full md:grid md:grid-cols-3 flex flex-col">
          <div className="md:border-r md:border-b-0 border-b col-span-1 p-4 h-[65vh] overflow-y-auto overflow-x-hidden">
            <div className="search relative">
              <input
                type="text"
                name=""
                id=""
                className="border-gray-300 border rounded-md h-10 w-full focus:outline-none p-2"
                placeholder="Search"
              />
              <FiSearch
                className="absolute top-[50%] right-4 -translate-y-[50%]"
                size={20}
              />
            </div>

            {loading?(<p className="text-center mt-4">Loading...</p>):""}
            {messages.length === 0? (<p className="mt-4">No any messages</p>):""}

            {!loading && messages.map((message) => (
              <div
                key={message.id}
                className="preview border-b border-gray-300 mt-4 p-2 flex justify-between relative"
                onMouseEnter={() => handleMouseEnter(message.id)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleSingleMessage(message.id)}
              >
                <div>
                  <h1 className="text-black font-[600] cursor-pointer">{message.full_name}</h1>
                  <p className="cursor-pointer">{truncateText(message.description, 5)}</p>
                </div>

                <div
                  className={`absolute right-0 top-[50%] -translate-y-[50%] px-2 ${
                    hoveredMessageId === message.id
                      ? "translate-x-0"
                      : "translate-x-10"
                  } transition-all ease-in-out duration-300 `}
                >
                  <MdDelete
                    size={18}
                    className="text-red-400 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteMessage(message.id);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="h-52 col-span-2 p-6">
            {Object.keys(singleMessage).length === 0? (<p>Select a message to preview</p>):""}
            <div>
              <h1 className="text-black font-[600]">
                {singleMessage.full_name}
              </h1>

              <p className="break-words">{singleMessage.email}</p>
              <p className="break-words">{singleMessage.phone}</p>
            </div>

            <div>
              <h1 className="text-black font-[600]">{singleMessage.subject}</h1>
              <p>{singleMessage.description}</p>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
