import React, { useState, useEffect, useRef, useReducer } from "react";
import { Col, Card, Button } from "antd";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi2";
import { FaRegEdit } from "react-icons/fa";
import { apiURL } from "../../assets/data/api";
import HTMLReactParser from "html-react-parser/lib/index";
import JoditEditor from "jodit-react"

export default function Career() {
  const [addCareer, setAddCareer] = useState(false);
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editCareerId, setEditCareerId] = useState(null);
  const [careerData, setCareerData] = useState({
    job_title: "",
    location: "",
    no_of_openings: "",
    job_description: "",
    job_specification: "",
    job_type: "",
  });

  const editor = useRef(null)

  useEffect(() => {
    setLoading(true);
    const fetchCareers = async () => {
      try {
        const response = await axios.get(`${apiURL}/career`);
        setCareers(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    fetchCareers();
  }, [flag]);

  useEffect(() => {
    if (editCareerId) {
      const fetchCareer = async () => {
        try {
          const response = await axios.get(`${apiURL}/career/${editCareerId}`);
          setCareerData({
            job_title: response.data.data.job_title,
            location: response.data.data.location,
            no_of_openings: response.data.data.no_of_openings,
            job_description: response.data.data.job_description,
            job_specification: response.data.data.job_specification,
            job_type: response.data.data.job_type,
          });
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchCareer();
    } else {
      setCareerData({
        job_title: "",
        location: "",
        no_of_openings: "",
        job_description: "",
        job_specification: "",
        job_type: "",
      });
    }
  }, [editCareerId]);

  const validateForm = () => {
    if (
      !careerData.job_title ||
      !careerData.location ||
      !careerData.job_type ||
      !careerData.job_description ||
      !careerData.job_specification ||
      !careerData.no_of_openings
    ) {
      setErrorMessage("Please fill in all required fields.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  // Set user-input data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCareerData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddCareer = async () => {
    if (validateForm()) {
      try {
        await axios.post(`${apiURL}/career`, careerData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        toast.success("Entry added");
        setCareerData({
          job_title: "",
          location: "",
          no_of_openings: "",
          job_description: "",
          job_specification: "",
          job_type: "",
        });
        setFlag(!flag); // Update flag to trigger re-fetch
        setAddCareer(false);
      } catch (error) {
        toast.error("Failed to add entry");
        console.log(error.message);
      }
    }
  };

  const handleDeleteCareer = async (id) => {
    try {
      await axios.delete(`${apiURL}/career/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setFlag(!flag);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again");
      console.log(error.message);
    }
  };

  const handleUpdateCareer = async () => {
    if (validateForm()) {
      try {
        await axios.put(`${apiURL}/career/${editCareerId}`, careerData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        setAddCareer(false);
        setEditCareerId(null);
        setCareerData({
          job_title: "",
          location: "",
          no_of_openings: "",
          job_description: "",
          job_specification: "",
          job_type: "",
        });
        setFlag(!flag); // Update flag to trigger re-fetch
        toast.success("Updated successfully");
      } catch (error) {
        toast.error("Failed to update. Please try again");
        console.log(error.message);
      }
    }
  };

  return (
    <>
      <Toaster />
      {!addCareer && !editCareerId && (
        <div>
          <div className="heading flex items-center w-full justify-between p-[16px]">
            <h1 className="text-xl font-[600] relative">Current Openings</h1>
            {loading && (
              <div className="flex w-full justify-center absolute">
                <p>Loading...</p>
              </div>
            )}
            <Button
              onClick={() => setAddCareer(true)}
              className="border-[#1890ff]"
            >
              Add Opening
            </Button>
          </div>

          <Col xs="24" xl={24}>
            {careers.length === 0 && !loading? (
              <p className="p-4">No openings available</p>
            ) : (
              ""
            )}
            {careers.map((career) => (
              <Card
                key={career.id}
                className="min-h-64 criclebox tablespace !mb-4 relative"
              >
                <div className="px-[24px] py-[16px] pb-0 flex gap-6 items-center">
                  <div className="flex items-start justify-start flex-col">
                    <div className="flex justify-start items-start pr-[24px] flex-col gap-2">
                      <h2 className="text-xl font-[600] group text-black relative w-max">
                        {career.job_title}
                      </h2>

                      <div className="flex items-center gap-8">
                        <p>{career.location}</p>
                        <p>{career.job_type}</p>
                        <p>Openings: {career.no_of_openings}</p>
                      </div>

                      <div>
                        <h2 className="text-black font-[600] justify-start">
                          Job Description:
                        </h2>
                        <p>{HTMLReactParser(career.job_description)}</p>
                      </div>

                      <div>
                        <h2 className="text-black font-[600] justify-start">
                          Job Specification:
                        </h2>
                        <p>{HTMLReactParser(career.job_specification)}</p>
                      </div>

                      <div className="flex items-center gap-4 absolute top-0 right-0 my-5 mx-6">
                        <FaRegEdit
                          size={18}
                          className="hover:text-blue-400 cursor-pointer icon-hover"
                          onClick={() => {
                            setEditCareerId(career.id);
                            setAddCareer(true);
                          }}
                        />
                        <HiOutlineTrash
                          size={18}
                          className="hover:text-red-400 cursor-pointer icon-hover"
                          onClick={() => handleDeleteCareer(career.id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </Col>
        </div>
      )}

      <Col xs="24" xl={24}>
        {addCareer && (
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 relative"
            title="Add Career"
          >
            <RxCross1
              className="absolute top-5 right-8 cursor-pointer"
              onClick={() => {
                setAddCareer(false);
                setEditCareerId(null);
              }}
            />
            <form action="" className="py-4 px-[25px] flex flex-col gap-4">
              <div className="flex items-center gap-8 flex-wrap justify-between">
                <div>
                  <label htmlFor="job_title" className="font-semibold">Title:</label>
                  <input
                    type="text"
                    name="job_title"
                    className="border rounded-md h-9 w-72 mx-2 p-2 focus:outline-none"
                    value={careerData.job_title}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex items-center">
                  <label htmlFor="location" className="font-semibold">Location:</label>
                  <input
                    type="text"
                    name="location"
                    className="border rounded-md h-9 w-60 mx-2 p-2 focus:outline-none"
                    value={careerData.location}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="job_type" className="font-semibold">Job-type:</label>
                  <select
                    name="job_type"
                    id="job_type"
                    className="bg-blue-400 rounded-md p-1 px-2 text-white mx-2 hover:bg-opacity-75 transition-all ease-in-out duration-300"
                    value={careerData.job_type}
                    onChange={handleChange}
                  >
                    <option value="part-time">Part-time</option>
                    <option value="full-time">Full-time</option>
                    <option value="contract">Contract</option>
                    <option value="temporary">Temporary</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="no_of_openings" className="font-semibold">Openings:</label>
                  <input
                    type="text"
                    name="no_of_openings"
                    className="border rounded-md h-9 w-16 mx-2 p-2 focus:outline-none"
                    value={careerData.no_of_openings}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="job_description" className="font-semibold">Job-Description:</label>
                  <JoditEditor
                  ref={editor}
                    theme="snow"
                    className="w-full !text-md"
                    value={careerData.job_description}
                    onChange={(newContent) =>
                      setCareerData((prevData) => ({
                        ...prevData,
                        job_description: newContent,
                      }))
                    }
                  />
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <label htmlFor="job_specification" className="font-semibold">Job-Specification:</label>

                  <JoditEditor
                  ref={editor}
                    className="w-full"
                    value={careerData.job_specification}
                    onChange={(newContent) =>
                      setCareerData((prevData) => ({
                        ...prevData,
                        job_specification: newContent,
                      }))
                    }
                  />
                  {/* <ReactQuill
                    theme="snow"
                    className="w-full"
                    value={careerData.job_specification}
                    onChange={(newContent) =>
                      setCareerData((prevData) => ({
                        ...prevData,
                        job_specification: newContent,
                      }))
                    }
                  /> */}
                </div>
              </div>

              {errorMessage && (
                <p className="text-sm !text-red-500 !font-normal">
                  {errorMessage}
                </p>
              )}

              <Button
                type="button"
                className="border-[#1890ff] !w-28 self-center mt-4"
                onClick={editCareerId ? handleUpdateCareer : handleAddCareer}
              >
                Done
              </Button>
            </form>
          </Card>
        )}
      </Col>
    </>
  );
}
