import React from "react";
import Team from "../components/Team"

const Who_we_are = () => {
  return (
    <>
      <div className=" flex flex-col gap-5 mx-auto">
        <h3 className="text-center text-3xl md:text-4xl font-medium">
          Who we are ?
        </h3>
        <p className="text-justify text-[19px]">
          We are a collective of passionate innovators, tech enthusiasts, and
          creative minds unified by a common purpose: to revolutionize the
          digital sphere. Beyond our expertise in app and website development,
          our identity is woven from the threads of diversity, collaboration,
          and a relentless pursuit of excellence. We thrive on fostering an
          environment that encourages curiosity, embraces diversity of thought,
          and celebrates each individual's unique strengths. At our core, we
          believe in the power of teamwork and the synergy it generates,
          propelling us toward our shared vision. Our identity is not just
          defined by what we do, but by the values we uphold — integrity,
          creativity, and a commitment to pushing boundaries. We are more than a
          company; we are a vibrant community driven by a collective passion to
          innovate, create, and leave an indelible mark on the digital
          landscape.
        </p>
        <div className="our-team">
          <Team/>
        </div>
      </div>
    </>
  );
};

export default Who_we_are;
