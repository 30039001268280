import { Col, Card, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { FaRegEdit } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi2";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { apiURL } from "../../assets/data/api";
import JoditEditor from "jodit-react"
import HTMLReactParser from "html-react-parser/lib/index";

export default function Testimonials() {
  const [addTestimonial, setAddTestimonial] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editTestimonialId, setEditTestimonialId] = useState(null);
  const [testimonialData, setTestimonialData] = useState({
    name: "",
    content: "",
    designation: "",
    image: null,
  });

  const editor = useRef(null)


  useEffect(() => {
    setLoading(true);
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${apiURL}/testimonial`);
        setTestimonials(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    fetchTestimonials();
  }, [flag]);

  useEffect(() => {
    if (editTestimonialId) {
      const fetchTestimonial = async () => {
        try {
          const response = await axios.get(
            `${apiURL}/testimonial/${editTestimonialId}`
          );
          setTestimonialData({
            name: response.data.data.name,
            content: response.data.data.content,
            designation: response.data.data.designation,
            image: response.data.data.image,
          });
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchTestimonial();
    } else {
      setTestimonialData({ title: "", content: "", image: null });
    }
  }, [editTestimonialId]);

  const validateForm = () => {
    if (
      !testimonialData.name ||
      !testimonialData.designation ||
      !testimonialData.content
    ) {
      setErrorMessage("Please fill in all required fields.");
      return false;
    } else if (!testimonialData.image) {
      setErrorMessage("Please select an image");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  // Set user-input data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestimonialData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Set uploaded file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setTestimonialData((prevData) => ({ ...prevData, image: file }));
  };

  const handleAddTestimonial = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("name", testimonialData.name);
      formData.append("content", testimonialData.content);
      formData.append("designation", testimonialData.designation);
      formData.append("image", testimonialData.image);
      try {
        await axios.post(`${apiURL}/testimonial`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Testimonial added");
        setTestimonialData({
          name: "",
          content: "",
          designation: "",
          image: null,
        });
        setFlag(!flag); // Update flag to trigger re-fetch
      } catch (error) {
        toast.error("Failed to add service");
        console.log(error.message);
      }
      setAddTestimonial(false);
    }
  };

  const handleDeleteTestimonial = async (id) => {
    try {
      await axios.delete(`${apiURL}/testimonial/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setFlag(!flag);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again");
      console.log(error.message);
    }
  };

  const handleUpdateTestimonial = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("name", testimonialData.name);
      formData.append("content", testimonialData.content);
      formData.append("designation", testimonialData.designation);
      formData.append("image", testimonialData.image);
      try {
        await axios.put(
          `${apiURL}/testimonial/${editTestimonialId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setAddTestimonial(false);
        setEditTestimonialId(null);
        setTestimonialData({
          name: "",
          content: "",
          designation: "",
          image: null,
        });
        setFlag(!flag); // Update flag to trigger re-fetch
        toast.success("Updated successfully");
      } catch (error) {
        toast.error("Failed to update. Please try again");
        console.log(error.message);
      }
    }
  };

  return (
    <>
      <div className="tabled relative">
        <Toaster />
        {!addTestimonial && !editTestimonialId && (
          <div>
            <div className="heading flex items-center w-full justify-between p-[16px]">
              <h1 className=" text-xl font-[600]">Testimonials</h1>
              {loading && (
                <div className="flex w-full justify-center absolute">
                  <p>Loading...</p>
                </div>
              )}
              <Button
                onClick={() => setAddTestimonial(true)}
                className="border-[#1890ff]"
              >
                Add Testimonial
              </Button>
            </div>

            <Col xs="24" xl={24}>
              {testimonials.length === 0 && !loading ? (
                <p className="p-4">No any testimonials</p>
              ) : (
                ""
              )}
              {testimonials.map((testimonial) => (
                <Card
                  className="min-h-52 criclebox tablespace !mb-4 relative"
                  key={testimonial.id}
                >
                  <div className="px-[24px] py-[16px] flex gap-6 items-center w-56">
                    <img
                      src={testimonial.image}
                      alt=""
                      className="h-44 rounded-full w-fit object-cover"
                    />
                    <div className="flex items-start flex-col">
                      <div className="flex justify-between items-center pr-[24px]">
                        <h2 className="text-xl font-[600] group text-black relative w-max">
                          <span>
                            {testimonial.name} ({testimonial.designation})
                          </span>
                          <span className="absolute -bottom-1 left-0 w-0 transition-all h-0.5 bg-gray-400 group-hover:w-full duration-300"></span>
                        </h2>
                        <div className="flex items-center gap-4 absolute top-0 right-0 my-5 mx-6">
                          <FaRegEdit
                            size={18}
                            className="hover:text-blue-400 cursor-pointer icon-hover"
                            onClick={() => {
                              setEditTestimonialId(testimonial.id);
                              setAddTestimonial(true);
                            }}
                          />
                          <HiOutlineTrash
                            size={18}
                            className="hover:text-red-400 cursor-pointer icon-hover"
                            onClick={() =>
                              handleDeleteTestimonial(testimonial.id)
                            }
                          />
                        </div>
                      </div>

                      <p className="mt-2 w-[900px]">
                        {HTMLReactParser(testimonial.content)}
                      </p>
                    </div>
                  </div>
                </Card>
              ))}
            </Col>
          </div>
        )}

        <Col xs="24" xl={24}>
          {addTestimonial && (
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 relative"
              title="Add Testimonial"
            >
              <RxCross1
                className="absolute top-5 right-8 cursor-pointer"
                onClick={() => {
                  setAddTestimonial(false);
                  setEditTestimonialId(null);
                }}
              />
              <form action="" className="py-4 px-[25px] flex flex-col gap-4">
                <div className="flex items-center gap-8 flex-wrap justify-between">
                  <div>
                    <label htmlFor="title" className="font-semibold">
                      Full Name:
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="border rounded-md h-9 w-64 mx-2 p-2 focus:outline-none"
                      value={testimonialData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="designation" className="font-semibold">
                      Designation:
                    </label>
                    <input
                      type="text"
                      name="designation"
                      className="border rounded-md h-9 w-52 mx-2 p-2 focus:outline-none"
                      value={testimonialData.designation}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="image" className="font-semibold">
                      Image:
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      className="mx-2 file:py-2 file:rounded-md file:px-4 file:bg-blue-400 file:text-white file:outline-none file:border-none cursor-pointer file:hover:bg-opacity-75 file:bg-opacity-100 transition-all ease-in-out duration-300 file:transition-all file:ease-in-out file:duration-300"
                      onChange={handleImageChange}
                    />
                  </div>

                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="centent" className="font-semibold">
                      Testimonial:
                    </label>
                    <JoditEditor
                      ref={editor}
                      className="w-full"
                      value={testimonialData.content}
                      onChange={(newContent) =>
                        setTestimonialData((prevData) => ({
                          ...prevData,
                          content: newContent,
                        }))
                      }
                    />
                  </div>
                </div>

                {errorMessage && (
                  <p className="text-sm !text-red-500 !font-normal">
                    {errorMessage}
                  </p>
                )}

                <Button
                  type="button"
                  className="border-[#1890ff] !w-28 self-center mt-4"
                  onClick={
                    editTestimonialId
                      ? handleUpdateTestimonial
                      : handleAddTestimonial
                  }
                >
                  Done
                </Button>
              </form>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}
