import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiURL } from "../assets/data/api";
import { Card } from "antd";
import { NavLink } from "react-router-dom";
import HTMLReactParser from "html-react-parser/lib/index";

export default function Career() {
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchCareers = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/career`
        );
        setCareers(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    fetchCareers();
  }, []);
  return (
    <div className="min-h-[100vh]">
      <div className="h-[17vh] w-full relative">
        <h2 className="z-20 absolute bottom-0 px-8 text-gray-200">
          <NavLink to="/" className="text-gray-200 hover:text-gray-200">
            GTS /
          </NavLink>
          <span className="ml-2">Career</span>
        </h2>
      </div>
      <div className="projects-content flex flex-col justify-center items-center gap-4 -mt-8 py-8 mx-8">

        <h2 className="text-[44px]">Current Openings</h2>

        <div className="flex gap-8 flex-wrap justify-center flex-col items-start">
        {careers.length === 0 && loading === false ? (
              <p className="p-4">No any openings currently.</p>
            ) : (
              ""
            )}
            {loading?(<p className="p-4 text-center w-full">Loading...</p>):""}
          {careers.map((career) => (
              <Card key={career.id} className="min-h-64 criclebox tablespace !mb-4 relative w-full">

              <div className="px-[24px] py-[16px] pb-0 flex gap-6 items-center">

                <div className="flex items-start justify-start flex-col">

                  <div className="flex justify-start items-start pr-[24px] flex-col gap-2">
                    <h2 className="text-2xl font-[600] group text-black relative w-max text-hover">
                      {career.job_title}
                    </h2>

                    <div className="flex items-center gap-8">
                      <p className="!text-md">{career.location}</p>
                      <p className="!text-md">{career.job_type}</p>
                      <p className="!text-md">Openings: {career.no_of_openings}</p>
                    </div>

                    <div>
                      <h2 className="text-black font-[600] justify-start text-xl">Job Description:</h2>
                      <p className="!text-md">{HTMLReactParser(career.job_description)}</p>
                    </div>

                    <div>
                      <h2 className="text-black font-[600] justify-start text-xl">Job Specification:</h2>
                      <p className="!text-md">{HTMLReactParser(career.job_specification)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
          {!loading? (<p className="text-center w-full text-md">To apply, please send your resume to geektechpvtltd@gmail.com</p>):""}
        </div>
      </div>
    </div>
  );
}

