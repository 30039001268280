import React from "react";
import {
  testimonial,
  webapp1,
  webapp2,
  webapp3,
  WebAppService,
} from "../assets/data/assets";
import "../scss/globals.scss";
// import { useTheme } from '../context/ThemeContext';

const WebAppPage = () => {
  // const {isDarkMode, toogleDarkMode}= useTheme();
  return (
    <div className="webapp-service-div ">
      {/* {isDarkMode ?<MdLightMode className="vid-icon" />  :  <MdDarkMode className="vid-icon" />} */}
      <div className="contact-bg-img">
        <h2>Desktop App</h2>
      </div>
      <div className="top-detail-div">
        <div className="service-div">
          <div className="left-div">
            <h2>Our Service</h2>
            <img src={WebAppService} alt="" className="our-service-img" />
          </div>
          <div className="right-div">
            <div className="top-div">
              <h3 style={{ marginTop: '60px' }}>Geek Tech Solution</h3>                 <p>
                Welcome to Geek Tech Solution, where innovation meets excellence
                in the world of information technology. Our comprehensive range
                of services is designed to empower businesses, streamline
                operations, and foster growth in the rapidly evolving digital
                landscape.
              </p>
              <br />
            </div>
            <div className="middle-div">
              <h3>Why Choose Geek Tech Solution?</h3>

              <p>
                Elevate your business with Geek Tech Solution a trusted partner
                committed to excellence. We blend cutting-edge technology,
                unparalleled expertise, and a client-centric approach to deliver
                tailored solutions. Choose us for innovation, reliability, and a
                collaborative journey towards your success.
              </p>
              <br />
            </div>
            <div className="bottom-div">
              <h3>Our Expertise</h3>
              <p>
                With a dedicated team of seasoned professionals, Geek Tech
                Solution brings a wealth of expertise in custom software
                development, cloud solutions, cybersecurity, and more. We
                empower businesses with strategic insights and top-notch
                solutions, ensuring they thrive in the dynamic landscape of
                technology
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mid-div">
        <div className="mid-left-div">
          <h2 >Why Choose Geek Tech Solution</h2>
          <h2>For Desktop Application Development ?</h2>
          <br />
          <p>
            Choosing a technology solution or service provider for web
            evelopment is a crucial decision that can significantly impact the
            success of your project. Here are some points highlighting why you
            might consider Geek Tech Solution for web development:
          </p>
          <br />
          <h3>Expertise and Experience</h3>
          <p>
            Geek Tech Solution boasts a team of experienced and skilled web
            developers with a proven track record in the industry. The company's
            expertise covers a wide range of desktop development technologies,
            frameworks, and programming languages.
          </p>
          <br />
          <h3>Innovative Solutions</h3>
          <p>
            Geek Tech Solution is known for its innovative approach to desktop
            development, staying abreast of the latest industry trends and
            incorporating cutting-edge technologies into their solutions.
          </p>
          <br />
          <h3>Customized Solutions</h3>
          <p>
            The company is committed to providing tailored desktop development
            solutions that align with the unique requirements and goals of each
            client.We work closely with clients to understand their business
            needs and deliver solutions that address specific challenges.
          </p>
          <br /> <h3>Responsive Design</h3>
          <p>
            Geek Tech Solution prioritizes responsive app, ensuring that
            app are optimized for a seamless user experience across various
            screen sizes.
          </p>
          <br />
          <h3>Scalability</h3>
          <p>
            Desktop development solutions are designed with scalability in mind,
            allowing your desktop application to grow and adapt to
            changing business requirements
          </p>
          <br />
          <h3>Security Measures</h3>
          <p>
            Geek Tech Solution implements robust security measures to protect
            desktop application from potential threats, ensuring data
            integrity and user privacy..
          </p>
          <br />
          <h3>Timely Delivery</h3>
          <p>
            Our company is known for its commitment to meeting project
            deadlines. Timely delivery of desktop development projects is a key
            aspect of their service.
          </p>
          <br />
          <h3> Collaborative Approach </h3>
          <p>
            Geek Tech Solution emphasizes collaboration with clients throughout
            the development process, fostering open communication and
            transparency.
          </p>
          <br />
          <h3>Post-Launch Support</h3>
          <p>
            Beyond the initial development phase, Geek Tech Solution provides
            post-launch support and maintenance services to address any issues,
            implement updates, and ensure ongoing performance.
          </p>
        </div>
        <div className="mid-right-div">
          <div className="image4">
            <img src={webapp1} alt="" />
          </div>
          <div className="image5">
            <img src={webapp2} alt="" />
          </div>
          <div className="image6">
            <img src={webapp3} alt="" />
          </div>
        </div>
      </div>
      <div className="last-div">
        <div className="last-left-div">
          <h3>Face of Satisfaction</h3>
          <div className="image4">
            <img src={testimonial} alt="Client testimonial image" />
          </div>
        </div>

        <div className="last-right-div">
          <h3>A Client Testimonial for Geek Tech Solution</h3>
          <br />

          <p>
            I am delighted to share my exceptional experience with Geek Tech
            Solutions. From the inception of my project to its completion, their
            team demonstrated unwavering professionalism, expertise, and a
            genuine commitment to delivering outstanding results. The level of
            detail and creativity invested in my website development far
            exceeded my expectations.
          </p>
          <br />
          <p>
            Geek Tech Solutions not only met but exceeded every milestone,
            providing a seamless and collaborative experience. Their ability to
            understand and translate my vision into a functional and visually
            stunning website was truly commendable. The team's responsiveness,
            transparent communication, and post-launch support further
            solidified my confidence in their capabilities. I wholeheartedly
            recommend Geek Tech Solutions to anyone seeking top-tier web
            development services – a team that goes above and beyond to turn
            ideas into digital masterpieces.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WebAppPage;
