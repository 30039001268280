import React, { useState, useEffect, useRef } from "react";
import { MenuItems } from "../assets/data/Data";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import logo from "../assets/images/Logo/CompanyLogo/logo.png";
import { LuMenu } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import { NavLink, useNavigate } from "react-router-dom";

const NewNavbar = () => {
  const [activeIndexes, setActiveIndexes] = useState({});
  const [sidenav, setSidenav] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (index, level) => {
    setActiveIndexes((prevState) => ({
      ...prevState,
      [level]: prevState[level] === index ? null : index,
    }));
  };

  const handleNavigation = (path) => {
    if (path) {
      setActiveIndexes({}); // Reset activeIndexes to close dropdowns
      navigate(path);
      setSidenav(false); // Close the sidenav if a navigation occurs
    }
  };

  const renderSubMenu = (subMenu, level = 0) => {
    return (
      <ul
        className={`absolute z-20 rounded shadow-lg ${
          level === 0 ? "bg-extra-l-blue" : "bg-l-blue"
        } min-w-max`}
      >
        {subMenu.map((item, index) => (
          <li key={index} className="relative">
            <span
              className="px-4 py-2 cursor-pointer hover:bg-white hover:text-black transition-all ease-in-out duration-100 flex items-center justify-between rounded"
              onClick={(e) => {
                e.stopPropagation();
                if (item.subMenu) {
                  handleMenuClick(index, level);
                } else {
                  handleNavigation(item.path);
                }
              }}
            >
              <span>{item.title}</span>
              {item.subMenu && (
                <span className="ml-2 text-sm !text-white">
                  {activeIndexes[level] === index ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </span>
              )}
            </span>
            {activeIndexes[level] === index &&
              item.subMenu &&
              renderSubMenu(item.subMenu, level + 1)}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu-container")) {
        setActiveIndexes({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-d-blue text-white flex justify-between items-center px-8 text-[18px] fixed top-0 z-30 w-screen">
      <NavLink to="/">
      <div className="logo">
        <img src={logo} alt="Company Logo" />
      </div>
      </NavLink>
      <ul className="flex space-x-10 mt-4 max-[860px]:hidden">
        {MenuItems.map((item, index) => (
          <li
            key={index}
            className="relative cursor-pointer text-hover menu-container"
            onClick={() => {
              if (item.subMenu) {
                handleMenuClick(index, 0);
              } else {
                handleNavigation(item.path);
              }
            }}
          >
            <span className="flex items-center">
              <span>{item.title}</span>
              {item.subMenu && (
                <span className="ml-2 text-sm !text-white">
                  {activeIndexes[0] === index ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </span>
              )}
            </span>
            {activeIndexes[0] === index &&
              item.subMenu &&
              renderSubMenu(item.subMenu, 1)}
          </li>
        ))}
      </ul>

      {/* menubar icon*/}
      <div className="menubar min-[860px]:hidden">
        <LuMenu
          size={25}
          onClick={() => {
            setSidenav(!sidenav);
          }}
          className="cursor-pointer"
        />
      </div>

      {/* side navbar for mobile screens */}
      <div
        className={`h-[90vh] w-52 bg-d-blue absolute right-0 top-0 z-30 py-10 flex flex-col items-center gap-4 min-[860px]:hidden ${
          sidenav ? "translate-x-0" : "translate-x-52"
        } transition-all ease-in-out duration-300`}
      >
        <RxCross2
          className="absolute top-0 right-0 mx-4 my-4 cursor-pointer"
          onClick={() => setSidenav(false)}
        />
        <ul className="flex flex-col space-y-10 menu-container">
          {MenuItems.map((item, index) => (
            <li
              key={index}
              className="relative cursor-pointer"
              onClick={() => {
                if (item.subMenu) {
                  handleMenuClick(index, 0);
                } else {
                  handleNavigation(item.path);
                }
              }}
            >
              <span className="flex items-center">
                <span>{item.title}</span>
                {item.subMenu && (
                  <span className="ml-2 text-sm !text-white">
                    {activeIndexes[0] === index ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </span>
                )}
              </span>
              {activeIndexes[0] === index &&
                item.subMenu &&
                renderSubMenu(item.subMenu, 1)}
            </li>
          ))}
        </ul>
      </div>

      {/* overlay */}
      <div
        className={`overlay h-[90vh] z-20 w-full absolute top-0 left-0 bg-slate-800 ${
          sidenav ? "block bg-opacity-50" : "hidden"
        }`}
        onClick={() => setSidenav(false)}
      ></div>
    </nav>
  );
};

export default NewNavbar;
