import React, { useState } from "react";
import "../scss/globals.scss";
import {
  FaSquareFacebook,
  ImLinkedin,
  BsTwitterX,
  IoLogoYoutube,
  contact,
} from "../assets/data/assets";
import { NavLink } from "react-router-dom";
import { apiURL } from "../assets/data/api";
import {toast, Toaster} from "react-hot-toast";
import axios from "axios";

const ContactPage = () => {
  // const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState({
    full_name:"",
    email:"",
    phone:"",
    subject:"",
    description:"",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async ()=>{
      try{
        await axios.post(`${apiURL}/contact`, contactData);
          toast.success("Message sent");
          setContactData({
            full_name:"",
            email:"",
            phone:"",
            subject:"",
            description:"",
          })
      }catch(error){
        const response = JSON.parse(error.request.response);
        toast.error(response.details[0].message);
      }
  }

  // TODO: implement contact us after api works

  return (
    <>
    <Toaster/>
      <div className="contact-us-page">
      <div className="h-[17vh] w-full relative">
        <h2 className="z-20 absolute bottom-0 px-8 text-gray-200">
          <NavLink to="/" className="text-gray-200 hover:text-gray-200">
            GTS /
          </NavLink>
          <span className="ml-2">Contact Us</span>
        </h2>
      </div>

        <div className="contact-container">
          <section className="col-right">
            <div className="sendMessage">
              <h2>SEND US A MESSAGE</h2>
            </div>

            <form className="message-form">
              <div className="input-group ">
                <label htmlFor="full_name">Full name:</label>
                <input
                  type="text"
                  name="full_name"
                  required="required"
                  placeholder="Your Name"
                  className="input-field"
                  onChange={handleChange}
                  value={contactData.full_name}
                ></input>
              </div>

              <div className="input-group ">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  name="email"
                  required="required"
                  placeholder="Your Email"
                  className="input-field"
                  onChange={handleChange}
                  value={contactData.email}
                ></input>
              </div>

              <div className="input-group ">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="phone"
                  name="phone"
                  required="required"
                  placeholder="Your Phone"
                  className="input-field"
                  onChange={handleChange}
                  value={contactData.phone}
                ></input>
              </div>

              <div className="input-group ">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  name="subject"
                  required="required"
                  placeholder="Your Subject"
                  className="input-field"
                  onChange={handleChange}
                  value={contactData.subject}
                ></input>
              </div>

              <div className="input-group ">
                <label htmlFor="description">Message:</label>
                <textarea
                  required="required"
                  name="description"
                  placeholder="Your Message"
                  className="input-field"
                  onChange={handleChange}
                  value={contactData.description}
                ></textarea>
              </div>

              <div className="input-group !mt-6" onClick={handleSubmit}>
                <a className="contact-button">Submit</a>
              </div>
            </form>
          </section>
          <section className="col-left">
            <div className="contact-title ">
              <h2 className="">GET IN TOUCH WITH US</h2>
            </div>
            <img src={contact} alt="" className="img" />
          </section>
        </div>

        <div className="join-social">
          <h1>Join our community</h1>
          <ul>
            <li>
              <FaSquareFacebook />
            </li>
            <li>
              <ImLinkedin />
            </li>
            <li>
              <BsTwitterX />
            </li>
            <li>
              <IoLogoYoutube />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContactPage;