import { Col, Card, Button } from "antd";
import { useState, useEffect, useRef } from "react";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi2";
import { FaRegEdit } from "react-icons/fa";
import { apiURL } from "../../assets/data/api";
import JoditEditor from "jodit-react"
import HTMLReactParser from "html-react-parser/lib/index";

export default function Services() {
  const [addService, setAddService] = useState(false);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editServiceId, setEditServiceId] = useState(null);
  const [readMore, setReadMore] =useState(null);

  const [serviceData, setServiceData] = useState({
    title: "",
    content: "",
    image: null,
  });

  const editor = useRef(null)


  const truncateText = (text, numWords) => {
    const words = text.split(' ');
    if (words.length <= numWords) return text;
  
    return words.slice(0, numWords).join(' ') + '...';
  };

  const handleToggleExpansion = (id) => {
    setReadMore((prevId) => (prevId === id ? null : id));
  };


  useEffect(() => {
    setLoading(true);
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${apiURL}/service`);
        setServices(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    fetchServices();
  }, [flag]);

  useEffect(() => {
    if (editServiceId) {
      const fetchService = async () => {
        try {
          const response = await axios.get(
            `${apiURL}/service/${editServiceId}`
          );
          setServiceData({
            title: response.data.data.title,
            content: response.data.data.content,
            image: response.data.data.image,
          });
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchService();
    } else {
      setServiceData({ title: "", content: "", image: null });
    }
  }, [editServiceId]);

  const validateForm = () => {
    if (!serviceData.title || !serviceData.content) {
      setErrorMessage("Please fill in all required fields.");
      return false;
    } else if (!serviceData.image) {
      setErrorMessage("Please select an image");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  // Set user-input data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setServiceData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Set uploaded file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setServiceData((prevData) => ({ ...prevData, image: file }));
  };

  const handleAddService = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", serviceData.title);
      formData.append("content", serviceData.content);
      formData.append("image", serviceData.image);
      try {
        await axios.post(`${apiURL}/service`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Service added");
        setServiceData({ title: "", content: "", image: null });
        setFlag(!flag); // Update flag to trigger re-fetch
      } catch (error) {
        toast.error("Failed to add service");
        console.log(error.message);
      }
      setAddService(false);
    }
  };

  const handleDeleteService = async (id) => {
    try {
      await axios.delete(`${apiURL}/service/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setFlag(!flag);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("Failed to delete. Please try again");
      console.log(error.message);
    }
  };

  const handleUpdateService = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", serviceData.title);
      formData.append("content", serviceData.content);
      formData.append("image", serviceData.image);
      try {
        await axios.put(`${apiURL}/service/${editServiceId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setAddService(false);
        setEditServiceId(null);
        setServiceData({ title: "", content: "", image: null });
        setFlag(!flag); // Update flag to trigger re-fetch
        toast.success("Updated successfully");
      } catch (error) {
        toast.error("Failed to update. Please try again");
        console.log(error.message);
      }
    }
  };

  return (
    <>
      <div className="tabled relative h-fit">
        <Toaster />
        {!addService && !editServiceId && (
          <div>
            <div className="heading flex items-center w-full justify-between p-[16px]">
              <h1 className=" text-xl font-[600] relative">Our Services</h1>
              {loading && (
                <div className="flex w-full justify-center absolute">
                  <p>Loading...</p>
                </div>
              )}
              <Button
                onClick={() => setAddService(true)}
                className="border-[#1890ff]"
              >
                Add Service
              </Button>
            </div>
            <div className="flex justify-start flex-wrap gap-8 pb-8">
              {services.length === 0 && !loading? (
                <p className="p-4">No any services</p>
              ) : (
                ""
              )}
              {services.map((service) => (
                <Card
                  key={service.id}
                  title={service.title}
                  className={`w-[24rem] criclebox tablespace ${readMore? "h-fit": ""}`}
                  extra={
                    <img src={service.image} className="h-9 object-cover" />
                  }
                >
                  <p className="px-[25px] py-[16px] break-words">
                    {readMore? HTMLReactParser(service.content) : truncateText(HTMLReactParser(service.content), 50)}
                  <button className="text-sm text-center w-full mt-2 font-medium" onClick={()=>handleToggleExpansion(service.id)}>{readMore? 'Read Less' : 'Read More'}</button>
                  </p>
                  <div className="icons px-[25px] pb-[16px] flex items-center gap-4">
                    <FaRegEdit
                      size={18}
                      className="hover:text-blue-400 cursor-pointer icon-hover"
                      onClick={() => {
                        setEditServiceId(service.id);
                        setAddService(true);
                      }}
                    />
                    <HiOutlineTrash
                      size={18}
                      className="hover:text-red-400 cursor-pointer icon-hover"
                      onClick={() => handleDeleteService(service.id)}
                    />
                  </div>
                </Card>
              ))}
            </div>
          </div>
        )}

        <Col xs="24" xl={24}>
          {addService && (
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 relative"
              title="Add Service"
            >
              <RxCross1
                className="absolute top-5 right-8 cursor-pointer"
                onClick={() => {
                  setAddService(false);
                  setEditServiceId(null);
                }}
              />
              <form action="" className="py-4 px-[25px] flex flex-col gap-4">
                <div className="flex items-center gap-8 flex-wrap justify-between">
                  <div>
                    <label htmlFor="title" className="font-semibold">
                      Title:
                    </label>
                    <input
                      type="text"
                      name="title"
                      className="border rounded-md h-9 w-96 mx-2 p-2 focus:outline-none"
                      value={serviceData.title}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="image" className="font-semibold">
                      Image:
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      className="mx-2 file:py-2 file:rounded-md file:px-4 file:bg-blue-400 file:text-white file:outline-none file:border-none cursor-pointer file:hover:bg-opacity-75 file:bg-opacity-100 transition-all ease-in-out duration-300 file:transition-all file:ease-in-out file:duration-300"
                      onChange={handleImageChange}
                    />
                  </div>

                  <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="content" className="font-semibold">
                      Content:
                    </label>
                    <JoditEditor
                    ref={editor}
                    className="w-[100%]"
                      value={serviceData.content}
                      onChange={(newContent) =>
                        setServiceData((prevData) => ({
                          ...prevData,
                          content: newContent,
                        }))
                      }
                    />
                  </div>
                </div>

                {errorMessage && (
                  <p className="text-sm !text-red-500 !font-normal">
                    {errorMessage}
                  </p>
                )}

                <Button
                  type="button"
                  className="border-[#1890ff] !w-28 self-center mt-4"
                  onClick={
                    editServiceId ? handleUpdateService : handleAddService
                  }
                >
                  Done
                </Button>
              </form>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}
