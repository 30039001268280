import React, { useState } from "react";
import HTMLReactParser from "html-react-parser/lib/index";
import "../scss/globals.scss";
// import { useTheme } from "../context/ThemeContext";

const ServicesCard = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  // const { isDarkMode } = useTheme();

  const truncateText = (text, numWords) => {
    const words = text.split(' ');
    if (words.length <= numWords) return text;
    return words.slice(0, numWords).join(' ') + '...';
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    // <div className={` flex flex-col items-center transition-all hover:-translate-y-2 w-full lg:w-1/4 py-5 gap-4 cursor-pointer rounded-md ${isDarkMode ? "services-dark-mode" : "services-light-mode"}`}>
    <div className={`servicesCard flex flex-col items-center transition-all hover:-translate-y-2 !w-[40rem] lg:w-1/4 !py-6 gap-4 cursor-pointer rounded-md px-6 overflow-hidden ${isExpanded ? 'h-auto' : 'h-96'}`}>
      <div className="span-div">
        <img src={props.image} alt="" className="h-20" />
      </div>

      <div className="flex flex-col md:h-full h-[90%] overflow-hidden">
        <h4 className="text-[25px] font-normal">{props.title}</h4>
        <p className="text-[16px] h-fit">
          {isExpanded ? HTMLReactParser(props.content) : truncateText(HTMLReactParser(props.content), 70)}
        </p>
      </div>

      <button className="text-sm font-medium border mt-2 md:-mt-4 text-hover" onClick={handleToggleExpand}>
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
    </div>
  );
};

export default ServicesCard;
