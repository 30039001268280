import React from "react";
import {
  testimonial,
  website1,
  website2,
  website3,
  MdDarkMode,
  WebsiteService,
  MdLightMode,
  
} from "../assets/data/assets";
import "../scss/globals.scss";
// import { useTheme } from '../context/ThemeContext';

const Websites = () => {
  // const {isDarkMode, toogleDarkMode}= useTheme();


  return (
    <div className="Websites-service-div ">
      {/* {isDarkMode ?<MdLightMode className="vid-icon" />  :  <MdDarkMode className="vid-icon" />} */}
      <div className="contact-bg-img">
          <h2>Website</h2>
        </div>
        <div className="top-detail-div">
          <div className="service-div">
            <div className="left-div">
              <h2>Our Service</h2>
              <img src={WebsiteService} alt="" className="our-service-img" />
            </div>
            <div className="right-div">
              <div className="top-div">
              <h3 style={{ marginTop: '60px' }}>Geek Tech Solution</h3>     
                              <p>
                  Welcome to Geek Tech Solution, where innovation meets
                  excellence in the world of information technology. Our
                  comprehensive range of services is designed to empower
                  businesses, streamline operations, and foster growth in the
                  rapidly evolving digital landscape.
                </p>
                <br />
              </div>
              <div className="middle-div">
                <h3>Why Choose Geek Tech Solution?</h3>

                <p>
                  Elevate your business with Geek Tech Solution a trusted
                  partner committed to excellence. We blend cutting-edge
                  technology, unparalleled expertise, and a client-centric
                  approach to deliver tailored solutions. Choose us for
                  innovation, reliability, and a collaborative journey towards
                  your success.
                </p>
                <br />
              </div>
              <div className="bottom-div">
                <h3>Our Expertise</h3>
                <p>
                  With a dedicated team of seasoned professionals, Geek Tech
                  Solution brings a wealth of expertise in custom software
                  development, cloud solutions, cybersecurity, and more. We
                  empower businesses with strategic insights and top-notch
                  solutions, ensuring they thrive in the dynamic landscape of
                  technology
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mid-div">
          <div className="mid-left-div">
            <h2>Why Choose Geek Tech Solution?</h2>
            <h2>For Websites Development</h2>
            <br />
            <p>
              Choosing Geek Tech Solution for website development offers several
              advantages that set us apart. Here are some compelling reasons to
              consider our services:
            </p>
            <br />
            <h3>Expertise</h3>
            <p>
              Our team comprises skilled and experienced professionals in
              website development. We stay updated with the latest technologies
              and trends to deliver cutting-edge solutions.
            </p>
            <br />
            <h3>Custom Solutions</h3>
            <p>
              We understand that each business is unique. Our approach involves
              creating tailored website solutions that align with your specific
              goals and requirements.
            </p>
            <br />
            <h3>Responsive Design</h3>
            <p>
              {" "}
              In an era where users access websites from various devices, we
              prioritize responsive design. Your website will look and function
              seamlessly across desktops, tablets, and mobile devices.
            </p>
            <br /> <h3>User-Centric Approach</h3>
            <p>
              User experience is at the forefront of our design and development
              process. We ensure that your website is intuitive, easy to
              navigate, and provides a positive experience for visitors.
            </p>
            <br />
            <h3>Scalability</h3>
            <p>
              We design and develop websites with scalability in mind. As your
              business grows, your website can easily accommodate increased
              traffic and additional features without compromising performance.
            </p>
            <br />
            <h3>Security</h3>
            <p>
              {" "}
              The security of your website and its data is a top priority for
              us. We implement robust security measures to protect against
              potential threats and vulnerabilities.
            </p>
            <br />
            <h3>Timely Delivery</h3>
            <p>
              We understand the importance of meeting deadlines. Our team is
              committed to delivering projects on time, ensuring that you can
              launch your website according to your schedule.
            </p>
            <br />
            <h3> Continuous Support </h3>
            <p>
              Our relationship doesn't end with the development phase. We offer
              ongoing support and maintenance services to address any issues,
              implement updates, and ensure the long-term success of your
              website.
            </p>
            <br />
            <h3>Cost-Effective Solutions</h3>
            <p>
              We offer competitive pricing without compromising on quality. Our
              goal is to provide cost-effective solutions that deliver value for
              your investment.
            </p>
          </div>
          <div className="mid-right-div">
            <div className="image1">
              <img src={website1} alt="" />
            </div>
            <div className="image2">
              <img src={website3} alt="" />
            </div>
            <div className="image3">
              <img src={website2} alt="" />
            </div>
          </div>
        </div>
        <div className="last-div">
          <div className="last-left-div">
            <h3>Face of Satisfaction</h3>
            <div className="image4">
              <img src={testimonial} alt="Client testimonial image" />
            </div>
          </div>

          <div className="last-right-div">
            <h3>A Client Testimonial for Geek Tech Solution</h3>
            <br />

            <p>
              I am delighted to share my exceptional experience with Geek Tech
              Solutions. From the inception of my project to its completion,
              their team demonstrated unwavering professionalism, expertise, and
              a genuine commitment to delivering outstanding results. The level
              of detail and creativity invested in my website development far
              exceeded my expectations.
            </p>
            <br />
            <p>
              Geek Tech Solutions not only met but exceeded every milestone,
              providing a seamless and collaborative experience. Their ability
              to understand and translate my vision into a functional and
              visually stunning website was truly commendable. The team's
              responsiveness, transparent communication, and post-launch support
              further solidified my confidence in their capabilities. I
              wholeheartedly recommend Geek Tech Solutions to anyone seeking
              top-tier web development services – a team that goes above and
              beyond to turn ideas into digital masterpieces.
            </p>
          </div>
        </div>
      </div>
    
  );
};

export default Websites;
