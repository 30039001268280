import React from "react";
import{FaCss3, FaHtml5, FaNode, FaPython, FaReact} from "../assets/data/assets"
import "../scss/globals.scss"
// import {useTheme} from "../context/ThemeContext"
const Expertise = () => {
  // const { isDarkMode } = useTheme();
  return (
    // <section
    //   className={` Expertise ${
    //     isDarkMode ? "expertise-dark-mode" : "expertise-light-mode"
    //   }`}
    // >
      <section className="Expertise">
      <div className="container">
        <div
          className="text-div flex flex-col"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
          }}
        >
          <h2 className="mb-2 section-t">Our Expertise</h2>
          <p style={{ marginBottom: "20px", padding:"0 2rem" }}>
            "A software company excels in crafting innovative and efficient
            digital solutions, leveraging cutting-edge technologies to address
            diverse business needs. With expertise in software development,
            customization, and optimization, these companies empower clients
            with tailored solutions to enhance productivity and drive
            technological advancements."
          </p>
        </div>
        <div className="icon-div">
          <div className="list-card-div">
            <li className="li">
              <FaReact className="icon react" />
            </li>
          </div>

          <div className="list-card-div">
            <li>
              <FaPython className="icon python" />
            </li>
          </div>

          <div className="list-card-div">
            <li>
              <FaNode className="icon node" />
            </li>
          </div>

          <div className="list-card-div">
            <li>
              <FaHtml5 className="icon html" />
            </li>
          </div>

          <div className="list-card-div">
            <li>
              <FaCss3 className="icon csss" />
            </li>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
