import React from "react";
import "../scss/globals.scss";
// import {useTheme} from "../context/ThemeContext"
import { about, MobileImage2 } from "../assets/data/assets";
import { Link } from "react-router-dom";
import { FaReadme, FaSearch } from "react-icons/fa";

const About = () => {
  // const { isDarkMode } = useTheme();
  return (
    // <section
    //   className={`about bg-black section-p ${
    //     isDarkMode ? "about-dark-mode" : "about-light-mode"
    //   }`}
    //   id="about"
    // >
    <section className="about py-8" id="about">

      <div className="container">

        <div className="about-content text-center">
          <div className="content-right flex items-center md:gap-8 flex-wrap md:flex-nowrap">
            <div className="section-t">
              <h3>Welcome to Geek Tech </h3>
              <p className="text">
                At Geek Tech Solution, we are passionate about technology and
                thrive on solving complex challenges. We are dedicated to
                delivering superior results that exceed expectations and drive
                measurable business growth for our clients. With our expertise
                and commitment to excellence, we are confident in our ability to
                provide you with innovative website and mobile app solutions
                that will elevate your digital presence.
              </p>
              <p className="text">
                Geek Tech Solutions Pvt Ltd is an IT company that will help you
                grow your business by building websites and mobile applications.
              </p>
            </div>

            <div className="h-full w-full">
              <img src={about} alt="" className="rounded max-h-80 max-w-[30rem] min-h-[180px] min-w-[270px]" />
            </div>
          </div>
        </div>

        <div className="about-content text-center">
          <div className="content-right flex items-center md:gap-8 flex-wrap md:flex-nowrap">
            <div className="section-t">
              <h3>Know Us Better </h3>
              <p className="text">
                Founded in 2023, Geek Tech Solutions Pvt. Ltd. stands as a best
                software firm in Nepal. Our dedicated team comprises
                approximately 10 professionals engaged in the creation,
                enhancement, testing, and upkeep of a remarkable portfolio .
                Additionally, our skilled workforce meticulously organizes and
                evaluates extensive data to assist clients in making informed
                decisions through analytics.
              </p>

              <Link
                to="about/who-we-are"
                onClick={() => {
                  window.scrollTo(0, 200);
                }}
              >
                <button>
                  <FaReadme /> Read more
                </button>
              </Link>
            </div>

            <div className="h-full w-full">
              <img src={MobileImage2} alt="" className="rounded max-h-80 max-w-[30rem] min-h-[180px] min-w-[270px]" />
            </div>
          </div>
        </div>
      </div>
      <div className="second-about-section">
        <div className="text-div">
          <h2>Want to work with us?</h2>
          <p>
            Our success is the product of our talented staff. We offer employees
            great growth opportunities, a team environment, and a chance to work
            at one of the fastest growing healthcare information companies. From
            information technologists to developers, data scientists, network
            engineers, designers and more, we’re continually looking for top
            talent to fill the important positions in the teams.
          </p>
        </div>
        <Link
          to="/career"
          onClick={() => {
            window.scrollTo(0, 600);
          }}
        >
          <button>
            <FaSearch />
            Join us{" "}
          </button>
        </Link>
      </div>
    </section>
  );
};

export default About;
