import React from "react";
// import { HiLightBulb } from "react-icons/hi";
// import { GoRocket } from "react-icons/go";
// import { FcProcess } from "react-icons/fc";
// import TeamImg from './assets/team.jpg'
// import { FaRegCalendarDays } from "react-icons/fa6";
// import { FaThumbsUp } from "react-icons/fa";
// import { PiNotePencil } from "react-icons/pi";
// import { FaTrophy } from "react-icons/fa6";
import { NavLink, Outlet } from "react-router-dom";

const About = () => {
  return (
    <div className="w-full ">
      <div className="h-[17vh] w-full relative">
        <h2 className="z-20 absolute bottom-0 px-8 text-gray-200">
          <NavLink to="/" className="text-gray-200 hover:text-gray-200">
            GTS /
          </NavLink>
          <span className="ml-2">About Us</span>
        </h2>
      </div>
      <div className="mid px-[2rem]">
        {/* <div className="w-full bg-gradient-to-tl from-gray-700 via-gray-900 to-black h-60 flex items-center justify-center">
        <h3 className="font-bold text-white text-3xl">About Us</h3>
      </div>
      <h1 className="text-center text-3xl my-6 font-bold">
        We’re Global, But you’re No.1
      </h1>
      <section className="info-section grid grid-cols-1 md:grid-cols-2 gap-4 px-8 justify-center">
        <div className="left-side flex justify-center items-center flex-col bg-gradient-to-r from-gray-100 to-gray-300 p-4">
          <h3 className="text-2xl mb-2 font-medium">
            We are the whole bunch of experts.
          </h3>
          <p className="text-justify">
            Sydney Digital Group is not your conventional software solutions
            company. Our vision is nothing less than realizing the full
            potential of your business – in software, web identity by design and
            presence – to drive a new era of development, growth, and
            productivity. Our vision is to bring your business into the 21st
            century through innovation and modern technology. Our main purpose
            is to boost your revenue through digital marketing tools. Creating a
            Brand Awareness campaign through the many advertising tools that we
            have access to, will give you a well embedded digital presence We
            attend to everything you need from initial consultation and needs
            assessment to professional services which includes Business .
          </p>
        </div>
        <div className="right-side flex justify-center items-center flex-col bg-gradient-to-r from-gray-100 to-gray-300 p-4">
          <h3 className="text-2xl mb-2 font-medium">
            Marketing Strategies to Increase Your Sales Revenue!
          </h3>
          <p className="text-justify">
            One of the first things your customers see representing you business
            is your logo. Your logo is your brand. There are many key elements
            that comprise of the logo from colour schemes, to choice of wording
            to placement. All these play an important part in branding. We want
            to evoke the exact emotional response from your clients that you
            want. BUSINESS IDENTITY STRATEGY Creating a Strong and Lasting
            Business Presence is important. Your Brand Awareness is whats’s
            going to bring in the clients. Leaving an identity that has a
            lasting impact on clients and improves brand awareness. Research
            suggests that when sending correspondences, using a branded package
            automatically lets the receiver make a connection to your business,
            creates brand awareness and makes your contact info always
            accessible.
          </p>
        </div>
      </section>
      <section className="mb-8">
        <div className="flex justify-center">
          <h3 className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 m-6 p-4 font-medium md:w-1/4 text-center w-auto hover:text-white">
            Our work process
          </h3>
        </div>
        <div className="flex flex-col md:flex-row items-center md:justify-around  gap-4">
        <div className="thinking flex flex-col items-center">
        <HiLightBulb className="text-6xl sm:text-8xl hover:text-white" />
        <p className="text-xl font-medium">Critical Thinking</p>
        </div>
         <div className="process flex flex-col items-center">
         <FcProcess className="text-6xl sm:text-8xl" />
         <p className="text-xl font-medium">Successful Execution</p>
         </div>
          <div className="rocket flex flex-col items-center">
          <GoRocket className="text-6xl sm:text-8xl hover:text-white" />
          <p className="text-xl font-medium">Successful Deployment</p>
          </div>
          
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-4 px-8 justify-center">
        <div className="left-think bg-gradient-to-r from-gray-100 to-gray-300 p-4">
          <h3 className="text-2xl text-center mb-4">Think Out Of Box</h3>
          <p className="text-justify">
            There are so many options when taking your business forward to this
            digital era. Sydney Digital are experienced in thinking outside the
            box when it comes to developing your ideas. We can make your ideas a
            digital reality. With over 30 years in business development, the
            Sydney Digital Group experts can bring to life your business
            concept. Our Business Branding package has the ability to transform
            your business targeting your main audience. Our Software Solutions
            experts can streamline your workflows and digitize the process to
            boost key areas in your business. Give us a call to chat or send us
            an email to find out how we can make business better.
            box when it comes to developing your ideas. We can make your ideas a
            digital reality. With over 30 years in business development, the
            Sydney Digital Group experts can bring to life your business
            concept. Our Business Branding package has the ability to transform
            your business targeting your main audience. Our Software Solutions
            experts can streamline your workflows and digitize the process to
            boost key areas in your business. Give us a call to chat or send us
            an email to find out how we can make business better.
          </p>
        </div>
        <div className="right-think bg-black-500">
        <img src={TeamImg} alt="Our Team" />
        </div>
      </section>
      <div className="our-work flex flex-col md:flex-row md:justify-around mt-12 gap-8 md:gap-0 px-8">
        <div className="calendar flex items-center gap-2 hover:text-white">
<FaRegCalendarDays className="text-6xl "/>
<p className="font-bold text-2xl">30 <span className="block">Years Of Experience</span></p>
        </div>
        <div className="thumbs flex items-center gap-2 hover:text-white">
            <FaThumbsUp className="text-6xl "/>
            <p className="font-bold text-2xl">500+<span className="block">Happy Clients</span></p>
        </div>
        <div className="diary flex items-center gap-2 hover:text-white">
            <PiNotePencil className="text-6xl "/>
            <p className="font-bold text-2xl">1000+<span className="block">Successful Projects</span></p>
        </div>
        <div className="awards flex items-center gap-2 hover:text-white">
        <FaTrophy className="text-6xl " />
            <p className="font-bold text-2xl">17<span className="block">Awards</span></p>
        </div>
      </div>
      <div className="footer h-60 font-bold text-center mt-8">
      </div> */}
        <Outlet />
      </div>
    </div>
  );
};

export default About;
