import React from "react";
import { HiLightBulb } from "react-icons/hi";
import { GoRocket } from "react-icons/go";
import { FcProcess } from "react-icons/fc";
import { FaRegCalendarDays } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa";
import { PiNotePencil } from "react-icons/pi";
import { FaTrophy } from "react-icons/fa6";

const Mission = () => {
  return (
    <div className="mx-auto  flex flex-col gap-8">
      
    <h3 className="text-center md:text-left underline text-[2.75rem]  font-medium mt-5 mb-5">Our Mission and Goals</h3>
      <p className="text-justify text-[19px]">
        "At GTS Pvt. Ltd, our mission resonates with the relentless pursuit of
        crafting innovative digital experiences that transcend boundaries. We
        are driven by a singular vision — to engineer not just apps and
        websites, but transformative solutions that elevate businesses. Our goal
        is to redefine the digital landscape, merging cutting-edge technology
        with creative prowess to deliver seamless, user-centric platforms. With
        a steadfast commitment to excellence, we embark on a journey fueled by
        innovation, pushing the boundaries of possibility. Every line of code,
        every pixel, embodies our dedication to empowering businesses to thrive
        in the ever-evolving digital sphere. GTS Pvt. Ltd stands as a beacon of
        innovation, shaping the future of digital experiences."
      </p>
       <h3 className="text-center md:text-left underline text-2xl md:text-4xl font-medium mt-5 mb-5">Our way of approach</h3>
      <div className="flex  flex-col md:flex-row items-center md:justify-around  gap-6">
        <div className="thinking flex flex-col items-center ">
        <HiLightBulb className="text-6xl sm:text-8xl hover:text-white" />
        <h4 className="text-xl font-medium my-2">Critical Thinking</h4>
        <p className="text-justify">Critical thinking is the cornerstone of informed decision-making and problem-solving. It's a cognitive skill that involves analyzing, evaluating, and synthesizing information to form reasoned judgments. Engaging in critical thinking goes beyond accepting information at face value; it's about actively questioning, reasoning, and exploring ideas to understand their validity and implications.</p>
        </div>
         <div className="process flex flex-col items-center">
         <FcProcess className="text-6xl sm:text-8xl" />
         <h4 className="text-xl font-medium my-2">Successful Execution</h4>
         <p className="text-justify text-[19px]">Critical thinking is the cornerstone of informed decision-making and problem-solving. It's a cognitive skill that involves analyzing, evaluating, and synthesizing information to form reasoned judgments. Engaging in critical thinking goes beyond accepting information at face value; it's about actively questioning, reasoning, and exploring ideas to understand their validity and implications.</p>
         </div>
          <div className="rocket flex flex-col items-center">
          <GoRocket className="text-6xl sm:text-8xl hover:text-white" />
          <h4 className="text-xl font-medium my-2">Successful Deployment</h4>
          <p className="text-justify text-[19px]">Critical thinking is the cornerstone of informed decision-making and problem-solving. It's a cognitive skill that involves analyzing, evaluating, and synthesizing information to form reasoned judgments. Engaging in critical thinking goes beyond accepting information at face value; it's about actively questioning, reasoning, and exploring ideas to understand their validity and implications.</p>
          </div>
    </div>
       
    
    <h3 className="text-center md:text-left underline text-2xl md:text-4xl font-medium mt-5 mb-5">Our Accompolishment</h3>
    <div className="our-work flex flex-col md:flex-row md:justify-around  gap-8 md:gap-0 p-8">
        <div className="calendar flex items-center gap-2 hover:text-white">
<FaRegCalendarDays className="text-6xl "/>
<p className="font-bold text-xl md:text-2xl">30 <span className="block">Years Of Experience</span></p>
        </div>
        <div className="thumbs flex items-center gap-2 hover:text-white">
            <FaThumbsUp className="text-6xl "/>
            <p className="font-bold text-xl md:text-2xl">500+<span className="block">Happy Clients</span></p>
        </div>
        <div className="diary flex items-center gap-2 hover:text-white">
            <PiNotePencil className="text-6xl "/>
            <p className="font-bold text-xl md:text-2xl">1000+<span className="block">Successful Projects</span></p>
        </div>
        <div className="awards flex items-center gap-2 hover:text-white">
        <FaTrophy className="text-6xl " />
            <p className="font-bold text-xl md:text-2xl">17<span className="block">Awards</span></p>
        </div>
      </div>
     
<div>

</div>
    </div>
  )
};

export default Mission;
