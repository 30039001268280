import { Route, Routes } from "react-router-dom";
import Home from "../pages/admin-dashboard/Home";
import Profile from "../pages/admin-dashboard/Profile";
import Main from "../components/layout/Main";
import AdminLogin from "../pages/AdminLogin";
import Projects from "../pages/admin-dashboard/Projects";
import Services from "../pages/admin-dashboard/Services";
import Teams from "../pages/admin-dashboard/Teams";
import Testimonials from "../pages/admin-dashboard/Testimonials";
import ProtectedRoute from "./ProtectedRoute";
import Blogs from "../pages/admin-dashboard/Blogs";
import Career from "../pages/admin-dashboard/Career";
import Inbox from "../pages/admin-dashboard/Inbox";

const DashboardRoutes = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          }
        >
          <Route path="dashboard" element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route path="services" element={<Services />} />
          <Route path="teams" element={<Teams />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="career" element={<Career />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
};

export default DashboardRoutes;
