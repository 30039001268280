import React from 'react'

const Work_with_us = () => {
  return (
    <>
    
    <div className='flex flex-col gap-5 mx-auto'>
      
        <h3 className='text-center text-3xl md:text-4xl font-medium mb-5'>Our Work Culture</h3>
        <section className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-4 width-[100%] mb-8'>
            <div className=' border border-black  p-4 transform transition duration-300 hover:scale-105  w-[100%] '>
                <h4 className='text-2xl text-center mb-2'>Collaboration and Teamwork</h4>
                <p className='text-justify '>We believe in the power of collaboration. Our teams are diverse, bringing together unique perspectives and skill sets to foster an environment where everyone's voice is heard and valued. Working together synergistically, we achieve greater heights collectively than we could alone.</p>
            </div>
            <div className=' border border-black   p-4 transform transition duration-300 hover:scale-105  w-[100%]'>
                <h4 className='text-2xl text-center mb-2'>Innovation and Creativity</h4>
                <p className='text-justify'>Innovation is the heartbeat of our culture. We encourage out-of-the-box thinking and celebrate creativity. We're not afraid to challenge the status quo, continuously seeking better, smarter, and more efficient ways to tackle challenges.</p>
            </div>
            <div className=' border border-black   p-4 transform transition duration-300 hover:scale-105  w-[100%]'>
                <h4 className='text-2xl text-center mb-2'>Continuous Learning and Growth</h4>
                <p className='text-justify'>Learning is a journey, not a destination. We foster a culture of continuous learning and growth, providing opportunities for skill development, knowledge sharing and personal/professional advancement. We believe that every experience is an opportunity to learn and grow.</p>
            </div>
            <div className=' border border-black  p-4 transform transition duration-300 hover:scale-105   w-[100%]'>
                <h4 className='text-2xl text-center mb-2'>Work-Life Balance and Well-being</h4>
                <p className='text-justify'>
                We understand the importance of maintaining a healthy work-life balance. Our culture emphasizes the well-being of our employees, providing support systems, flexibility, and initiatives that promote physical, mental, and emotional wellness.
                </p>
            </div>
            <div className=' border border-black  p-4 transform transition duration-300 hover:scale-105  w-[100%]'>
                <h4 className='text-2xl text-center mb-2'>Customer-Centric Approach</h4>
                <p className='text-justify'>Our work culture revolves around our customers. We are dedicated to understanding their needs, exceeding their expectations, and delivering solutions that truly make a difference in their lives.</p>
            </div>
        </section>
    </div>
    </>
  )
}

export default Work_with_us